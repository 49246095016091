export const API_BASE_URL =
  localStorage.getItem("urlApi") || "https://api3.siriusalgo.com/";
export const getApiUrl = (endpoint) => API_BASE_URL + endpoint;

export const LOGIN = getApiUrl("login.php");
export const REGISTER = getApiUrl("register.php");
export const TARAMA = getApiUrl("search-all.php");
export const TARAMAV2 = getApiUrl("search-all-v2.php");
export const TARAMAV3 = getApiUrl("search-all-v3.php");
export const USERINFO = getApiUrl("user-info.php");
export const USERUPDATE = getApiUrl("user-update.php");
export const USERHIDE = getApiUrl("user-hide.php");
export const DESTEK = getApiUrl("tickets.php");
export const YENIDESTEK = getApiUrl("new-ticket.php");
export const DESTEKKAPAT = getApiUrl("close-ticket.php");
export const DESTEKCEVAP = getApiUrl("reply-ticket.php");
export const FAVORIESDATA = getApiUrl("favories-data.php");
export const PACKAGES = getApiUrl("packages.php");
export const PACKAGESLANDING = getApiUrl("packages-landing.php");
export const MARKETS = getApiUrl("markets.php");
export const GETORDERNUMBER = getApiUrl("get-order-number.php");
export const MAKEPAYMENT = getApiUrl("make-payment.php");
export const GETTAKSIT = getApiUrl("installments.php");
export const INDICATOR = getApiUrl("indicator.php");
export const INDICATORS = getApiUrl("indicators.php");
export const FILTERS = getApiUrl("filters.php");
export const VIDEOS = getApiUrl("videos.php");
export const GETLISTE = getApiUrl("lists.php");
export const ADDLISTE = getApiUrl("lists-add.php");
export const DELETELISTE = getApiUrl("lists-delete.php");
export const UPDATELISTE = getApiUrl("lists-update.php");
export const GETLISTEMARKET = getApiUrl("lists-markets.php");
export const GETFAVORI = getApiUrl("lists-favorites.php");
export const FAVORIADD = getApiUrl("lists-favorites-add.php");
export const FAVORIREMOVE = getApiUrl("lists-favorites-remove.php");
export const FORMULAS = getApiUrl("formulas.php");
export const FORMULADD = getApiUrl("formulas-add.php");
export const FORMULEDIT = getApiUrl("formulas-edit.php");
export const FORMULLIKE = getApiUrl("formulas-like.php");
export const FORMULDELETE = getApiUrl("formulas-delete.php");
export const FORMULASHARE = getApiUrl("formulas-share.php");
export const FORMULAUNSHARE = getApiUrl("formulas-unshare.php");
export const FORMULAFOLLOW = getApiUrl("formulas-follow.php");
export const FORMULASORT = getApiUrl("formulas-update-orders.php");
export const FORMULAUNFOLLOW = getApiUrl("formulas-unfollow.php");
export const USERFOLLOW = getApiUrl("user-follow.php");
export const USERFOLLOWS = getApiUrl("user-follows.php");
export const USERUNFOLLOW = getApiUrl("user-unfollow.php");
export const PARITES = getApiUrl("parity.php");
export const PANOFORMULLER = getApiUrl("pano-formulas.php");
export const SEARCHFORMUL = getApiUrl("search-formulas.php");
export const SEARCHUSER = getApiUrl("search-users.php");
export const USERPROFILE = getApiUrl("user-profile.php");
export const NOTIFICATIONS = getApiUrl("notifications.php");
export const ALARMS = getApiUrl("alarms.php");
export const ALARMSMESSAGES = getApiUrl("alarms-messages.php");
export const FORMULASETALARM = getApiUrl("formulas-alarms-set.php");
export const FORMULAUNSETALARM = getApiUrl("formulas-alarms-unset.php");
export const SORTFORMULAS = getApiUrl("sort-formulas.php");
export const SORTUSERS = getApiUrl("sort-users.php");
export const FORMULAKTAR = getApiUrl("formulas-transfer.php");
export const ODEMEGECMISI = getApiUrl("transactions.php");
