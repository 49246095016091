import React, { useEffect, useState } from "react";
import "./AnaKumanda.css";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import _, { uniqBy } from "lodash";
import Header from "../System/header";
import SideMenuPanel from "../SideMenu/SideMenuPanel";
import AnaKumandaSonucYok from "../svgs/anaKumandaSonucYok";
import { dataBorsa, dataYon2 } from "../../utils/dummyData";
import ButtonClose from "../svgs/buttonClose";
import { BsSearch } from "react-icons/bs";
import { FaFilterCircleXmark } from "react-icons/fa6";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import ArrowSort from "../svgs/arrowSort";
import ArrowDown from "../svgs/arrowDown";
import ArrowUp from "../svgs/arrowUp";
import TopluEkle from "../svgs/toplu";
import SonucSec from "../svgs/sonucSec";
import SonucTekEkle from "../svgs/sonucTekEkle";
import SonucAlarm from "../svgs/sonucAlarm";
import SonucGrafik from "../svgs/sonucGrafik";
import SonucSecildi from "../svgs/sonucSecildi";
import ArrowThinDown from "../svgs/arrowThinDown";
import ArrowThinUp from "../svgs/arrowThinUp";
import { PulseLoader } from "react-spinners";
import araCizgi from "../../assets/img/ara-cizgi.png";
import { Link, useLocation, useNavigation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { postDataWithHeaders } from "../../utils/apiUtils";
import { useDispatch, useSelector } from "react-redux";
import ChartComponent from "../chart/chart";
import PuanComponent from "../puan/puan";
import { HiRefresh } from "react-icons/hi";
import forinvest from "../../assets/img/forinvest.svg";

import { timeout } from "../../utils/helperFunction";
import {
  ADDLISTE,
  FAVORIADD,
  FORMULADD,
  GETLISTE,
  INDICATORS,
  UPDATELISTE,
  TARAMAV2,
  TARAMAV3,
} from "../../utils/urls";
import { createGlobalStyle } from "styled-components";

const thisBogaLists = ["GUVENLI", "OMO", "TOBO", "YELPAZE", "OLASIC"];

function objectLength(obj) {
  var result = 0;
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      // or Object.prototype.hasOwnProperty.call(obj, prop)
      result++;
    }
  }
  return result;
}

const objectToArrayFilters = (object) => {
  const arr = Object.keys(object).map((key) => ({
    slug: key,
    label: object[key].label,
    sort: object[key].sort,
  }));

  return arr.sort((a, b) => a.sort - b.sort);
};
function countMarket(objs) {
  var result = _.countBy(objs.flatMap((obj) => obj.market));
  return result;
}
let date = new Date();
var timestamp = date.getTime();

const zamansal = ["BAYZ", "BAYZ(YAKIN)", "GÖLGE", "ABC=DEF"];
const AnaKumanda = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.darkmode);
  const mobile = useSelector((state) => state.theme.mobile);
  const { user } = useSelector((state) => state.auth);
  const { filters } = useSelector((state) => state.filters);
  const dataFilters = filters;
  const urlApi = localStorage.getItem("urlApi");
  const [isProcessing, setIsProcessing] = useState(false);
  const [dahaGoster, setDahaGoster] = useState(false);
  const [isOpenShift, setIsOpenShift] = useState(false);
  const [dahaGosterId, setDahaGosterId] = useState(0);
  const [liste, setListe] = useState("");
  const [bayzJustClosed, setBayzJustClosed] = useState(
    localStorage.getItem("GenelbayzJustClosed") || 0
  );
  const [periyots, setPeriyots] = useState(
    JSON.parse(localStorage.getItem("periyots")) || []
  );
  const [search, setSearch] = useState("");
  const [alarm, setAlarm] = useState("");
  const [hisseType, setHisseType] = useState("Hissede");
  const [hisseAdet, setHisseAdet] = useState(0);
  const [ayrimi, setAyrimi] = useState(0);
  const [stratejiAdet, setStratejiAdet] = useState(0);
  const [taramaAdet, setTaramaAdet] = useState(0);
  const [selectListe, setSelectListe] = useState(0);
  const [selectListeTab, setSelectListeTab] = useState(1);
  const [alarmName, setAlarmName] = useState("");
  const [listeName, setListeName] = useState("");
  const [listeNameId, setListeNameId] = useState(0);
  const [listeler, setListeler] = useState([]);
  const [alarmFiyat, setAlarmFiyat] = useState("");
  const [periyotOpen, setPeriyotOpen] = useState("");
  const [yonOpen, setYonOpen] = useState("");
  const [yonHacimOpen, setYonHacimOpen] = useState("");
  const [yonOrtalamaOpen, setYonOrtalamaOpen] = useState("");
  const [yonIndicatorOpen, setYonIndicatorOpen] = useState("");
  const [formul, setFormul] = useState("");
  const [formulNote, setFormulNote] = useState("");
  const [markets, setMarkets] = useState([]);
  const [marketler, setMarketler] = useState([]);
  const [searchMarket, setSearchMarket] = useState([]);
  const [loadFilter, setLoadFilter] = useState(false);
  const [dataBody, setDataBody] = useState(null);
  const [dataBody2, setDataBody2] = useState(null);
  const [selectedBorsa, setSelectedBorsa] = useState(
    localStorage.getItem("selectedBorsa") || null
  );
  const [selectedBorsaId, setSelectedBorsaId] = useState(
    localStorage.getItem("selectedBorsaId") || 0
  );
  const [selectedPeriyot, setSelectedPeriyot] = useState(
    JSON.parse(localStorage.getItem("selectedPeriyot")) || []
  );
  const [selectedSembol, setSelectedSembol] = useState([]);
  const [formationLists, setFormationLists] = useState(
    JSON.parse(localStorage.getItem("formationLists")) || []
  );
  const [filtreFormationLists, setFiltreFormationLists] = useState(
    JSON.parse(localStorage.getItem("filtreFormationLists")) || []
  );
  const [filtreFormationLists2, setFiltreFormationLists2] = useState([]);
  const [hacimLists, setHacimLists] = useState(
    JSON.parse(localStorage.getItem("hacimLists")) || []
  );
  const [filtreHacimLists, setFiltreHacimLists] = useState(
    JSON.parse(localStorage.getItem("filtreHacimLists")) || []
  );
  const [ortalamaLists, setOrtalamaLists] = useState(
    JSON.parse(localStorage.getItem("ortalamaLists")) || []
  );
  const [filtreOrtalamaLists, setFiltreOrtalamaLists] = useState(
    JSON.parse(localStorage.getItem("filtreOrtalamaLists")) || []
  );
  const [indicatorLists, setIndicatorLists] = useState(
    JSON.parse(localStorage.getItem("indicatorLists")) || []
  );
  const [filtreIndicatorLists, setFiltreIndicatorLists] = useState(
    JSON.parse(localStorage.getItem("filtreIndicatorLists")) || []
  );
  const [selectedFormation, setSelectedFormation] = useState([]);
  const [selectedYon, setSelectedYon] = useState(
    localStorage.getItem("selectedYon") || ""
  );
  const [sonucYok, setSonucYok] = useState(false);
  const [updatedDataFormations, setUpdatedDataFormations] = useState(
    objectToArrayFilters(dataFilters.formations)
  );
  const [updatedDataHacim, setUpdatedDataHacim] = useState(
    objectToArrayFilters(dataFilters.volumes)
  );
  const [updatedDataIndicators, setUpdatedDataIndicators] = useState(
    objectToArrayFilters(dataFilters.indicators)
  );
  const [updatedDataOrtalamalar, setUpdatedDataOrtalamalar] = useState(
    objectToArrayFilters(dataFilters.averages)
  );
  const updateDataAll = updatedDataHacim.concat(
    updatedDataIndicators,
    updatedDataOrtalamalar
  );
  const [dataFilter, setDataFilter] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [sortType, setSortType] = useState(1);
  const [loadMarket, setLoadMarket] = useState(0);
  const [sortTypeChange, setSortTypeChange] = useState("default");
  const [topluEkleLists, setTopluEkleLists] = useState([]);
  const [visibleBorsa, setVisibleBorsa] = useState(false);
  const [visibleSembol, setVisibleSembol] = useState(false);
  const [visiblePeriyot, setVisiblePeriyot] = useState(false);
  const [visibleYon, setVisibleYon] = useState(false);
  const [visibleFiltre, setVisibleFiltre] = useState(false);
  const [visibleAlarm, setVisibleAlarm] = useState(false);
  const [visibleAlarmFiyat, setVisibleAlarmFiyat] = useState(false);
  const [visibleFormul, setVisibleFormul] = useState(false);
  const [visibleListelerim, setVisibleListelerim] = useState(false);
  const [dataFormation, setDataFormation] = useState([]);
  const [dataIndicator, setDataIndicator] = useState([]);
  const [formationId, setFormationId] = useState(0);
  const [puanName, setPuanName] = useState("");
  const [loadChart, setLoadChart] = useState("formation");
  const [modalIndicator, setModalIndicator] = useState(false);
  const handleIndicatorClose = () => setModalIndicator(false);
  const [modalHeikin, setModalHeikin] = useState(false);
  const handleHeikinClose = () => setModalHeikin(false);
  const [modalGrafik, setModalGrafik] = useState(false);
  const handleGrafikClose = () => setModalGrafik(false);
  const handleBorsaClose = () => setVisibleBorsa(false);
  const handleSembolClose = () => setVisibleSembol(false);
  const handlePeriyotClose = () => setVisiblePeriyot(false);
  const handleYonClose = () => setVisibleYon(false);
  const handleFiltreClose = () => setVisibleFiltre(false);
  const handleAlarmClose = () => setVisibleAlarm(false);
  const handleAlarmFiyatClose = () => setVisibleAlarmFiyat(false);
  const handleFormulClose = () => setVisibleFormul(false);
  const handleListelerimClose = () => _setVisibleListelerim();

  const _setVisibleListelerim = () => {
    setVisibleListelerim(false);
    setListe("");
    setSelectListe(0);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.shiftKey && event.key.toLowerCase() === "y") {
        event.preventDefault();
        setIsOpenShift(!isOpenShift);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const updatedDataFormationsLength =
    user.bayz == 1
      ? updatedDataFormations.length
      : updatedDataFormations.length - 4;

  const handleUyari = (name) => {
    toast.error(
      `${name} formülü özel Zamansallık Unsurları içermektedir. Premium+ paket özellikleri için 0530 238 60 18' e ulaşabilirsiniz.`
    );
  };
  const funcIsProcessing = async (deger, text = "") => {
    console.log("funcIsProcessing", deger, text);
    setIsProcessing(deger);
  };
  const _setDahaGoster = (id) => {
    if (id != dahaGosterId) {
      setDahaGoster(!dahaGoster);
      setDahaGosterId(id);
    } else {
      setDahaGoster(false);
      setDahaGosterId(0);
    }
  };
  const handleAlarmEkle = () => {
    funcIsProcessing(true, "handleAlarmEkle");
    setInterval(() => {
      funcIsProcessing(false);
      setVisiblePeriyot(false);
      setVisibleBorsa(false);
      setVisibleSembol(false);
      setVisibleYon(false);
      setVisibleFiltre(false);
    }, 2000);
  };
  const handlePeriyotOpen = (id) => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    if (id != periyotOpen) {
      setPeriyotOpen(id);
    } else {
      setPeriyotOpen("");
    }
  };
  const handleAyri = (value, text) => {
    setAyrimi(value);
    // console.log("ayrı", value, text);
  };
  const handleJust = (value, text) => {
    localStorage.setItem("GenelbayzJustClosed", value);
    // console.log("handleJust", value, text, filtreFormationLists);
    handleFormation("None");
    setBayzJustClosed(value);
  };
  const handlePeriyotSample = (value, period) => {
    console.log(
      "handlePeriyotSample filtreFormationLists",
      filtreFormationLists
    );
    let newFiltreFormationLists = filtreFormationLists;
    const bayzIndex = newFiltreFormationLists.findIndex(
      (item) => item.value === value
    );
    console.log("handlePeriyotSample", value, period, bayzIndex);
    // if (bayzIndex !== -1) {
    let oldPeriyot = newFiltreFormationLists[bayzIndex].periyot;
    console.log("oldPeriyot 0", oldPeriyot, newFiltreFormationLists[bayzIndex]);
    if (oldPeriyot.includes(period)) {
      oldPeriyot = oldPeriyot.filter((item) => item !== period);
      newFiltreFormationLists[bayzIndex].periyot = oldPeriyot;
      console.log("oldPeriyot 1", oldPeriyot);
    } else {
      newFiltreFormationLists[bayzIndex].periyot = [...oldPeriyot, period];
      console.log("oldPeriyot 2", oldPeriyot);
    }
    if (oldPeriyot.length == 0) {
      oldPeriyot = selectedPeriyot;
    }

    setPeriyotOpen("");
    // console.log(
    //   "handlePeriyotSample newFiltreFormationLists 3",
    //   newFiltreFormationLists,
    //   filtreFormationLists
    // );
    setFiltreFormationLists([...newFiltreFormationLists]);
  };
  const handleYonOpen = (id) => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    setYonOpen(id);
  };
  const handleYon = (value, yon) => {
    const bayzIndex = filtreFormationLists.findIndex(
      (item) => item.value === value
    );

    if (bayzIndex !== -1) {
      filtreFormationLists[bayzIndex].yon = yon;
    }
    //  console.log(
    //   "filtreFormationLists handleYon",
    //   value,
    //   yon,
    //   filtreFormationLists
    // );
    setFiltreFormationLists([...filtreFormationLists]);
    setYonOpen("");
  };
  const handleYonHacimOpen = (id) => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    setYonHacimOpen(id);
  };
  const handleHacimYon = (value, yon) => {
    const bayzIndex = filtreHacimLists.findIndex(
      (item) => item.value === value
    );

    if (bayzIndex !== -1) {
      filtreHacimLists[bayzIndex].yon = yon == "TÜMÜ" ? "TÜMÜ" : yon;
    }
    setFiltreHacimLists([...filtreHacimLists]);
    setYonHacimOpen("");
  };
  const handleYonOrtalamaOpen = (id) => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    setYonOrtalamaOpen(id);
  };
  const handleOrtalamaYon = (value, yon) => {
    const bayzIndex = filtreOrtalamaLists.findIndex(
      (item) => item.value === value
    );

    if (bayzIndex !== -1) {
      filtreOrtalamaLists[bayzIndex].yon = yon == "TÜMÜ" ? "TÜMÜ" : yon;
    }
    setFiltreOrtalamaLists([...filtreOrtalamaLists]);
    setYonOrtalamaOpen("");
  };
  const handleYonIndicatorOpen = (id) => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    setYonIndicatorOpen(id);
  };
  const handleIndicatorYon = (value, yon) => {
    const bayzIndex = filtreIndicatorLists.findIndex(
      (item) => item.value === value
    );

    if (bayzIndex !== -1) {
      filtreIndicatorLists[bayzIndex].yon = yon == "TÜMÜ" ? "TÜMÜ" : yon;
    }
    setFiltreIndicatorLists([...filtreIndicatorLists]);
    setYonIndicatorOpen("");
  };
  const _setVisibleFiltre = () => {
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    setVisibleFiltre(true);
  };
  const handleAlarmFiyatEkle = () => {
    funcIsProcessing(true, "handleAlarmFiyatEkle");
    setInterval(() => {
      funcIsProcessing(false);
      setVisiblePeriyot(false);
      setVisibleBorsa(false);
      setVisibleSembol(false);
      setVisibleYon(false);
      setVisibleFiltre(false);
    }, 2000);
  };

  const handlePeriyotValue = (value) => {
    return periyots.items.filter((item) => item.label == value)[0].value;
  };
  const handleYonValue = (value) => {
    return value == "TÜMÜ" ? 0 : value == "BOĞA" ? 1 : 2;
  };
  const handleFiltreChange = (lists, neresi) => {
    let newLists = {};
    console.log("neresi", neresi, lists);
    lists.map((item) => {
      let newPeriyots = [];
      if (neresi == "formations") {
        item.periyot.map((periyot) => {
          newPeriyots.push(handlePeriyotValue(periyot));
        });
      } else {
        newPeriyots.push(handlePeriyotValue(item.periyot));
      }
      newLists[item.value] = {
        periods: newPeriyots,
        side: handleYonValue(item.yon),
      };
    });
    return newLists;
  };

  const handleFormulEkle = () => {
    funcIsProcessing(true, "handleFormulEkle");
    const postData = {
      access_token: user.access_token,
      name: formul,
      note: formulNote,
      formations: handleFiltreChange(filtreFormationLists, "formations"),
      volumes: handleFiltreChange(filtreHacimLists, "volumes"),
      averages: handleFiltreChange(filtreOrtalamaLists, "averages"),
      indicators: handleFiltreChange(filtreIndicatorLists, "indicators"),
      seperate: ayrimi,
      bayz_just_closed: bayzJustClosed,
      // j1407b: 1,
    };
    postDataWithHeaders(FORMULADD, postData, dispatch).then((res) => {
      if (res.code === 0) {
        funcIsProcessing(false);
        setVisibleFormul(false);
        setFormul("");
        setFormulNote("");
        toast.success(res.data);
      } else {
        toast.error(res.data);
      }
    });
  };

  const handleListemeEkle = async () => {
    if (selectListe == "new") {
      funcIsProcessing(true, "handleListemeEkle");
      const postData = {
        access_token: user.access_token,
        name: liste,
        markets: [listeNameId],
        // j1407b: 1,
      };
      await postDataWithHeaders(ADDLISTE, postData, dispatch).then((res) => {
        // console.log("ADDLISTE", res);
        if (res.code === 0) {
          funcIsProcessing(false);
          setVisibleListelerim(false);
          setListe("");
          setSelectListe(0);
          toast.success(res.data);
        } else {
          funcIsProcessing(false);
          setVisibleListelerim(false);
          toast.error(res.data);
        }
      });
      funcIsProcessing(false);
      setVisibleListelerim(false);
      return;
    }
    selectListe.markets.push(parseInt(listeNameId));
    const postData = {
      id: selectListe.id,
      access_token: user.access_token,
      name: selectListe.name,
      markets: selectListe.markets,
      // j1407b: 1,
    };
    // // console.log("postData", postData);
    await postDataWithHeaders(UPDATELISTE, postData, dispatch).then((res) => {
      // console.log("ADDLISTE", res);
      if (res.code === 0) {
        funcIsProcessing(false);
        setVisibleListelerim(false);
        setSelectListe("");
        setListeName("");
        setListeNameId(0);

        toast.success(res.data);
      } else {
        setVisibleListelerim(false);
        funcIsProcessing(false);
        setSelectListe("");
        setListeName("");
        setListeNameId(0);
        toast.error(res.data);
      }
    });
  };
  const handleFavoriEkle = async () => {
    funcIsProcessing(true, "handleFavoriEkle");
    // // console.log("item", selectListe, listeNameId, selectListe.markets);
    const postData = {
      access_token: user.access_token,
      market_id: listeNameId,
      // j1407b: 1,
    };
    // // console.log("postData", postData);
    await postDataWithHeaders(FAVORIADD, postData, dispatch).then((res) => {
      // console.log("FAVORIADD", res);
      if (res.code === 0) {
        funcIsProcessing(false);
        setVisibleListelerim(false);
        setSelectListe("");
        setListeName("");
        setListeNameId(0);

        toast.success(res.data);
      } else {
        setVisibleListelerim(false);
        funcIsProcessing(false);
        setSelectListe("");
        setListeName("");
        setListeNameId(0);
        toast.error(res.data);
      }
    });
  };
  function periyotCallback(item) {
    return {
      value: item.name,
      label: item.description,
    };
  }
  const funcSelectBorsa = async (label, id) => {
    // console.log("funcSelectBorsa", label, id);
    setSelectedBorsa(label);
    setSelectedBorsaId(id);
    localStorage.setItem("selectedBorsa", label);
    localStorage.setItem("selectedBorsaId", id);
    setSelectedSembol([]);
    setLoadData(true);
    setSonucYok(true);
    let newMarket = [];
    let newValue = id;
    if (parseInt(newValue)) {
      newMarket = _.filter(markets.items, function (o) {
        return o.exchange_id == newValue;
      });
    } else {
      if (newValue == "XU030") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu030 == 1;
        });
      }
      if (newValue == "XU050") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu050 == 1;
        });
      }
      if (newValue == "XU100") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu100 == 1;
        });
      }
      if (newValue == "XBANK") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xbank == 1;
        });
      }
      if (newValue == "XKTUM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xktum == 1;
        });
      }
      // XUSIN, XUTEK, XILTM, XGIDA, XTRZM, XSGRT
      if (newValue == "XUSIN") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xusin == 1;
        });
      }
      if (newValue == "XUTEK") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xutek == 1;
        });
      }
      if (newValue == "XILTM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xiltm == 1;
        });
      }
      if (newValue == "XGIDA") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xgida == 1;
        });
      }
      if (newValue == "XTRZM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xtrzm == 1;
        });
      }
      if (newValue == "XSGRT") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xsgrt == 1;
        });
      }
      if (newValue == "CIRCLE") {
        newMarket = _.filter(markets.items, function (o) {
          return o.circle == 1;
        });
      }
      if (newValue == "XINDEX") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xindex == 1;
        });
      }
    }
    // console.log("newMarket", newMarket);
    setMarketler(newMarket);
    setSearchMarket(newMarket);
    setHisseAdet(0);
    setTaramaAdet(0);
    setDataBody([]);
    setVisibleBorsa(false);
  };
  function toggleElementInArray(array, element) {
    const index = array.indexOf(element);
    console.log("element", element);
    if (index !== -1) {
      // Element var, array'den çıkar
      array.splice(index, 1);
    } else {
      // Element yok, array'e ekle
      array.push(element);
    }
    return array;
  }
  const funcSelectPeriyot = (id) => {
    if (selectedPeriyot.includes(id)) {
      console.log("selectedPeriyot 1", selectedPeriyot);
      setSelectedPeriyot(selectedPeriyot.filter((item) => item !== id));
    } else {
      console.log("selectedPeriyot 2", selectedPeriyot);
      setSelectedPeriyot([...selectedPeriyot, id]);
    }
  };

  const funcSelectYon = (id) => {
    setSelectedYon(id);
    localStorage.setItem("selectedYon", id);
  };

  const funcAlarmFiyat = (id) => {
    setAlarmName(id);
    setVisibleAlarmFiyat(true);
  };

  const getListeler = async () => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };
    await postDataWithHeaders(GETLISTE, postData, dispatch).then((res) => {
      if (res.code === 0) {
        setListeler(res.data);
      }
    });
  };
  const funcListeEkle = async (form) => {
    setListeName(form.name);
    setListeNameId(parseInt(form.stock_id));
    await getListeler();
    setVisibleListelerim(true);
  };
  const funcListeTopluEkle = () => {
    // console.log("toplu");
    if (topluEkleLists.length > 0) {
      setVisibleListelerim(true);
    } else {
      toast.error("Liste Ekleme İşlemi İçin En Az Bir Sembol Seçmelisiniz");
    }
  };
  const funcSelectSembol = (id) => {
    if (selectedSembol.includes(id)) {
      setSelectedSembol(selectedSembol.filter((item) => item !== id));
    } else {
      setSelectedSembol([...selectedSembol, id]);
    }
    // console.log(selectedSembol);
  };

  const handleFormasyon = (value) => {
    // // console.log("handleFormasyon: ", updatedDataFormations, value);
    return updatedDataFormations.filter((v) => v.slug == value);
  };
  const handleIndicatorName = (value) => {
    // // console.log("handleIndicators: ", updatedDataIndicators, value);
    return updatedDataIndicators.filter((v) => v.slug == value);
  };
  const handleOrtalamaName = (value) => {
    // // console.log("handleOrtalamalar: ", updatedDataOrtalamalar, value);
    return updatedDataOrtalamalar.filter((v) => v.slug == value);
  };

  const handleHacimName = (value) => {
    // // console.log("handleHacimName: ", updatedDataHacim, value);
    return updatedDataHacim.filter((v) => v.slug == value);
  };
  const handlePeiods = async (value) => {
    // console.log("handlePeiods: ", periyots.items, value);
    const filteredItems = await periyots.items.filter((v) => v.label == value);
    // console.log("filteredItems", filteredItems[0].value);
    return filteredItems[0].value;
  };
  const handlePeriod2 = async (value) => {
    // // console.log("handlePeiods: ", periyots.items, value);
    const filteredItems = periyots.items.filter((v) => v.value === value);
    // // console.log("filteredItems", filteredItems[0].label);
    return filteredItems[0].label;
  };
  function findCommonItems(arrays) {
    if (arrays.length === 0) {
      return [];
    }
    const commonItems = arrays[0].filter((item) =>
      arrays.every((array) => array.some((aItem) => aItem.name === item.name))
    );

    return commonItems;
  }
  function uniqueStockIds(arr) {
    const uniqueIds = [...new Set(arr.map((item) => item.stock_id))];
    return uniqueIds;
  }
  function uniqueStockIds(arr) {
    const uniqueArray = arr.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t.stock_id === obj.stock_id)
    );
    return uniqueArray;
  }
  function searchByCriteria(data, formation, market, period) {
    return data.filter(
      (item) =>
        item.formation === formation &&
        item.market === market &&
        item.period === period
    );
  }
  function findStockCodes(data) {
    const stockCodeCount = {};
    const duplicateStockCodes = [];

    for (const item of data) {
      const stockCode = item.stock_code;
      if (stockCodeCount[stockCode]) {
        duplicateStockCodes.push(item);
      } else {
        stockCodeCount[stockCode] = 1;
      }
    }

    return duplicateStockCodes;
  }
  // Ortak elemanları bulan fonksiyon
  function findCommonElementsByName(...arrays) {
    if (arrays.length === 0) return [];

    // İlk dizideki name değerlerini bir Set'e ekleyin
    const firstArrayNames = new Set(arrays[0].map((item) => item.name));

    // Ortak elemanları bulmak için diğer dizileri kontrol edin
    for (let i = 1; i < arrays.length; i++) {
      const currentArrayNames = new Set(arrays[i].map((item) => item.name));
      for (const name of firstArrayNames) {
        if (!currentArrayNames.has(name)) {
          firstArrayNames.delete(name);
        }
      }
    }

    // Ortak name değerlerini bulduk, şimdi bu name değerlerine sahip tüm nesneleri döndürelim
    const commonNames = Array.from(firstArrayNames);
    const commonElements = arrays[0].filter((item) =>
      commonNames.includes(item.name)
    );

    return commonElements;
  }
  function intersectObjects(...objects) {
    // Ortak anahtarları bul
    const commonKeys = objects.reduce((common, obj) => {
      return common.filter((key) => key in obj);
    }, Object.keys(objects[0]));

    // Ortak anahtar-değer çiftlerini tek bir objede birleştir
    const result = {};
    commonKeys.forEach((key) => {
      // Tüm objelerde aynı değere sahip anahtarları kontrol et
      const isCommonValue = objects.every(
        (obj) => obj[key] === objects[0][key]
      );
      if (isCommonValue) {
        result[key] = objects[0][key];
      }
    });

    return result;
  }
  function mergeObjects(...objects) {
    const result = {};

    objects.forEach((obj) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          result[key] = obj[key];
        }
      }
    });

    return result;
  }
  function mergeByName(objectsArray) {
    const merged = {};

    objectsArray.forEach((array) => {
      array.forEach((obj) => {
        const name = obj.name;
        if (!merged[name]) {
          merged[name] = { ...obj };
        } else {
          merged[name] = { ...merged[name], ...obj };
        }
      });
    });

    return Object.values(merged);
  }
  function findCommonByName(objectsArray) {
    const nameCount = {};
    const commonObjects = {};

    // Tüm objeleri dolaşarak "name" sayısını hesapla
    objectsArray.forEach((array) => {
      array.forEach((obj) => {
        const name = obj.name;
        if (!nameCount[name]) {
          nameCount[name] = 0;
        }
        nameCount[name]++;
      });
    });

    // Ortak olan objeleri bul ve birleştir
    objectsArray.forEach((array) => {
      array.forEach((obj) => {
        const name = obj.name;
        if (nameCount[name] === objectsArray.length) {
          if (!commonObjects[name]) {
            commonObjects[name] = { ...obj };
          }
        }
      });
    });

    return Object.values(commonObjects);
  }
  const funcSelectSonuc = async () => {
    funcIsProcessing(true, "funcSelectSonuc");
    setDataBody(null);
    setDataFilter(null);
    // console.log("filtreFormationLists: ", filtreFormationLists);
    if (filtreFormationLists) {
      let formasyons = filtreFormationLists;
      let indicators = filtreIndicatorLists;
      let hacims = filtreHacimLists;
      let ortalamas = filtreOrtalamaLists;
      let dataOrtak = [];
      let ortakFormasyon = [];
      let ortakIndicator = [];
      let ortakHacim = [];
      let ortakOrtalamalar = [];
      // console.log("dataDummy", dataBody2[0]);
      const dataDummy = dataBody2[0].filter((item1) =>
        marketler.some((item2) => item2.name === item1.label)
      );
      if (formasyons.length > 0) {
        let tempData = [];
        let forms = [];

        if (ayrimi == 1) {
          // console.log("1");
          await Promise.all(
            formasyons.map(async (formasyon) => {
              let searchData = {};
              formasyon.periyot.map(async (periyot) => {
                let returnPeriyot = await handlePeiods(periyot);
                let searchData = {};
                // console.log("formasyon.yon", formasyon.yon);
                if (formasyon.yon == "TÜMÜ") {
                  if (formasyon.value == "BAYZ" && bayzJustClosed == 1) {
                    searchData = {
                      formation: handleFormasyon(formasyon.value)[0].slug,
                      market: formasyon.yon == "BOĞA" ? "BULL" : "BEAR",
                      period: returnPeriyot,
                      bayz_just_closed: "1",
                    };
                  } else {
                    searchData = {
                      formation: handleFormasyon(formasyon.value)[0].slug,
                      market: formasyon.yon == "BOĞA" ? "BULL" : "BEAR",
                      period: returnPeriyot,
                    };
                  }
                } else {
                  if (formasyon.value == "BAYZ" && bayzJustClosed == 1) {
                    searchData = {
                      formation: handleFormasyon(formasyon.value)[0].slug,
                      market: formasyon.yon == "BOĞA" ? "BULL" : "BEAR",
                      period: returnPeriyot,
                      bayz_just_closed: "1",
                    };
                  } else {
                    searchData = {
                      formation: handleFormasyon(formasyon.value)[0].slug,
                      market: formasyon.yon == "BOĞA" ? "BULL" : "BEAR",
                      period: returnPeriyot,
                    };
                  }
                }

                let searching = _.filter(dataDummy, {
                  formations: [searchData],
                });
                //// console.log("searching ayrı ayrı formasyons",searchData,searching);
                forms.push(searching);
              });
            })
          );

          await Promise.all(
            forms.map((subForms) => {
              subForms.map((sub) => {
                tempData.push(sub);
              });
            })
          );

          ortakFormasyon = _.uniqBy(tempData, "name");
        } else {
          let searching = [];
          let formationShow = [];

          await Promise.all(
            updatedDataFormations.map(async (formasyon) => {
              forms[formasyon.slug] = [];
            })
          );
          let DummyDataBody = dataDummy;
          // console.log("2");
          await Promise.all(
            formasyons.map(async (formasyon) => {
              console.log("formasyon", formasyon);
              let searchData = {};
              formasyon.periyot.map(async (periyot) => {
                let returnPeriyot = await handlePeiods(periyot);
                if (formasyon.yon == "TÜMÜ") {
                  // console.log("aa");
                  if (formasyon.value == "BAYZ" && bayzJustClosed == 1) {
                    searchData = {
                      formation: handleFormasyon(formasyon.value)[0].slug,
                      period: returnPeriyot,
                      bayz_just_closed: "1",
                    };
                  } else {
                    searchData = {
                      formation: handleFormasyon(formasyon.value)[0].slug,
                      period: returnPeriyot,
                    };
                  }
                } else {
                  if (formasyon.value == "BAYZ" && bayzJustClosed == 1) {
                    searchData = {
                      formation: handleFormasyon(formasyon.value)[0].slug,
                      market: formasyon.yon == "BOĞA" ? "BULL" : "BEAR",
                      period: returnPeriyot,
                      bayz_just_closed: "1",
                    };
                  } else {
                    searchData = {
                      formation: handleFormasyon(formasyon.value)[0].slug,
                      market: formasyon.yon == "BOĞA" ? "BULL" : "BEAR",
                      period: returnPeriyot,
                    };
                  }
                }
                searching = _.filter(DummyDataBody, {
                  formations: [searchData],
                });
                searching.map((item) => {
                  item.formations.map((form) => {
                    form.show = 0;
                  });
                });
                forms[formasyon.value].push(searching);
              });
            })
          );

          if (formasyons.length == 1) {
            Object.keys(forms).map((subForms) => {
              if (forms[subForms].length > 0) {
                forms[subForms].map((sub) => {
                  tempData.push(sub);
                });
              }
            });
            console.log("tempData 0", tempData);
            ortakFormasyon = mergeByName(tempData);
          } else {
            let tempData2 = [];
            Object.keys(forms).map((subForms, index) => {
              if (forms[subForms].length > 0) {
                tempData[subForms] = [];
                console.log("tempData forms[subForms][0]", forms[subForms]);
                forms[subForms].map((sub) => {
                  tempData[subForms].push(sub);
                });
                tempData2.push(mergeByName(tempData[subForms]));
              }
            });
            console.log("tempData 1", tempData);
            console.log("tempData 2", tempData2);
            ortakFormasyon = findCommonByName(tempData2);
          }

          // // console.log("formationShow: ", searching, formationShow);
        }

        console.log("tempData ortakFormasyon: ", ortakFormasyon);
      } else {
        //// console.log("no Filters", dataDummy);
        ortakFormasyon = dataDummy;
      }

      // const ortak_hisseler = [];
      if (indicators.length > 0) {
        // console.log("data indicators", indicators);
        let tempData = [];
        let indi = [];
        indicators.map((indicator) => {
          let slug_value = handleIndicatorName(indicator.value)[0].slug;
          // console.log("slug_value", slug_value);
          indi[slug_value] = [];
          let searchData = {};
          // console.log("indikator.yon", indicator.yon);
          if (indicator.yon == "TÜMÜ") {
            searchData = {
              slug: slug_value,
            };
          } else {
            searchData = {
              slug: slug_value,
              deger: indicator.yon == "BOĞA" ? "green" : "red",
            };
          }
          // console.log("indikator", searchData);
          let searching = _.filter(dataDummy, {
            indicators: [searchData],
          });
          //// console.log("searching indicators", searchData, searching);
          // indi.push(searching);
          indi[slug_value].push(searching);
        });
        Object.keys(indi).map((subForms) => {
          tempData.push(indi[subForms][0]);
        });
        // Ortak hisse senetlerini bul
        ortakIndicator = _.intersectionBy(...tempData, "name");

        // Sonuçları yazdırın
        // ortak_hisseler.push(ortak_indicators);
        console.log("indi ortakIndicator", ortakIndicator);
      }

      if (hacims.length > 0) {
        // console.log("data hacim", hacims);
        let tempData = [];
        let indi = [];
        hacims.map((hacim) => {
          let slug_value = handleHacimName(hacim.value)[0].slug;
          indi[slug_value] = [];
          let searchData = {};
          if (hacim.yon == "TÜMÜ") {
            searchData = {
              slug: slug_value,
            };
          } else {
            searchData = {
              slug: slug_value,
              deger: hacim.yon == "BOĞA" ? "green" : "red",
            };
          }
          let searching = _.filter(dataDummy, {
            indicators: [searchData],
          });
          //// // console.log("searching hacim", searchData, searching);
          // indi.push(searching);
          indi[slug_value].push(searching);
        });
        Object.keys(indi).map((subForms) => {
          tempData.push(indi[subForms][0]);
        });
        // Ortak hisse senetlerini bul
        ortakHacim = _.intersectionBy(...tempData, "name");

        // Sonuçları yazdırın
        // ortak_hisseler.push(ortak_hacim);
        console.log("indi ortakHacim:", ortakHacim);
      }

      if (ortalamas.length > 0) {
        //// console.log("data ortalama", dataOrtak);
        let tempData = [];
        let indi = [];
        ortalamas.map((ortalama) => {
          let slug_value = handleOrtalamaName(ortalama.value)[0].slug;
          ////// console.log("slug_value", slug_value);
          indi[slug_value] = [];
          let searchData = {};
          if (ortalama.yon == "TÜMÜ") {
            searchData = {
              slug: slug_value,
            };
          } else {
            searchData = {
              slug: slug_value,
              deger: ortalama.yon == "BOĞA" ? "green" : "red",
            };
          }
          let searching = _.filter(dataDummy, {
            indicators: [searchData],
          });
          // console.log("searching ortalama", searchData, searching);
          // indi.push(searching);
          indi[slug_value].push(searching);
        });
        Object.keys(indi).map((subForms) => {
          tempData.push(indi[subForms][0]);
        });
        // Ortak hisse senetlerini bul
        ortakOrtalamalar = _.intersectionBy(...tempData, "name");
        // Sonuçları yazdırın
        // ortak_hisseler.push(ortak_ortalama);
        console.log("indi ortakOrtalamalar:", ortakOrtalamalar);
      }
      // if (dataIndicatorHacim.length > 0 && dataOrtalamalar.length > 0) {
      //   ////// console.log("ortak_hisseler", ortak_hisseler);
      //   dataOrtak = _.intersectionWith(
      //     dataIndicatorHacim,
      //     dataOrtalamalar,
      //     _.isEqual
      //   );
      // }
      let arrays = [
        ortakFormasyon,
        ortakIndicator,
        ortakHacim,
        ortakOrtalamalar,
      ];

      // console.log("arrays", arrays);
      const filteredGroup = arrays.filter((arr) => arr.length > 0);
      // console.log("filteredGroup", filteredGroup);
      let commonElements = findCommonByName(filteredGroup);

      // console.log("commonElements", commonElements);

      dataOrtak = commonElements;
      if (ortakFormasyon.length == 0 && formasyons.length > 0) {
        dataOrtak = [];
      }
      if (ortakIndicator.length == 0 && indicators.length > 0) {
        dataOrtak = [];
      }
      if (ortakHacim.length == 0 && hacims.length > 0) {
        dataOrtak = [];
      }
      if (ortakOrtalamalar.length == 0 && ortalamas.length > 0) {
        dataOrtak = [];
      }
      // ////// console.log("_filteler: ", data, dataBody2);
      // localStorage.setItem("dataFilter", JSON.stringify(filterDatas));
      console.log("indi data", dataOrtak);
      if (selectedSembol.length > 0) {
        // console.log("selectedSembol", selectedSembol);
        const eslesenler = dataOrtak.filter((item) =>
          selectedSembol.includes(item.name)
        );

        dataOrtak = eslesenler;
      }

      let hisseAdet = objectLength(dataOrtak);
      let taramaAdet = Object.values(dataOrtak).reduce(
        (count, current) => count + current.formations.length,
        0
      );
      if (hisseAdet == 0) {
        taramaAdet = 0;
        hisseAdet = 0;
      }
      // console.log("dataOrtak", dataOrtak);

      setDataFilter(dataOrtak);
      setDataBody([dataOrtak]);
      setTaramaAdet(taramaAdet);
      setHisseAdet(hisseAdet);
      setVisibleFiltre(false);
      setVisibleSembol(false);
      timeout(1000);
      // console.log("finish filtering");
      funcIsProcessing(false);
    }
  };
  // Diğer handle fonksiyonları benzer şekilde yazılabilir

  const funcSelectPeriyotSonuc = () => {
    // setSonucYok(false);
    funcIsProcessing(true, "funcSelectPeriyotSonuc");
    timeout(1000);
    funcIsProcessing(false);
    setVisiblePeriyot(false);
  };

  const funcSelectYonSonuc = () => {
    // setSonucYok(false);
    funcIsProcessing(true, "funcSelectYonSonuc");
    timeout(1000);
    funcIsProcessing(false);
    setVisibleYon(false);
  };

  const handleSortType = (type) => {
    setSortType(type);
    setSortTypeChange("default");
  };
  const _listeSelect = (id) => {
    setSelectListe(id);
  };
  const handleSortingHeikin = () => {
    setModalHeikin(true);
  };
  function handleSortingChange(sorting, column) {
    // // console.log("sorting", sorting, "column", column);
    let data = [];
    let newData = dataFilter.length > 0 ? dataFilter : dataBody2[0];
    if (sorting === "default") {
      setSortTypeChange("down");
      data = _.orderBy(newData, [column], ["desc"]);
    }
    if (sorting === "down") {
      setSortTypeChange("up");
      data = _.orderBy(newData, [column], ["asc"]);
    }
    if (sorting === "up") {
      setSortTypeChange("default");
      data = newData;
    }
    setDataBody([data]);
  }

  const handleHeikinFiltre = (slug, deger, cmf) => {
    let data = [];
    let newData = dataFilter.length > 0 ? dataFilter : dataBody2[0];
    data = _.filter(newData, (item) => {
      if (cmf) {
        if (
          slug == "heikin" &&
          item.heikin === deger &&
          item.heikin_cmf <= -0.1
        ) {
          return item;
        }
        if (
          slug == "heikin_1w" &&
          item.heikin_1w === deger &&
          item.heikin_1w_cmf <= -0.1
        ) {
          return item;
        }
      }
      if (!cmf) {
        if (slug == "heikin" && item.heikin === deger) {
          return item;
        }
        if (slug == "heikin_1w" && item.heikin_1w === deger) {
          return item;
        }
      }
    });
    // console.log(`data ${slug}`, data);
    setDataBody([data]);
    let hisseAdet = objectLength(data);
    let taramaAdet = Object.values(data).reduce(
      (acc, current) => acc + current.formations.length,
      0
    );

    // console.log(hisseAdet, taramaAdet);
    setTaramaAdet(taramaAdet);
    setHisseAdet(hisseAdet);

    // handleHeikinClose();
  };

  function marketCallback(item) {
    return {
      value: item.id,
      stock_id: item.stock_id,
      label: item.symbol,
      exchange_id: item.exchange_id,
      xu030: item.xu030,
      xu050: item.xu050,
      xu100: item.xu100,
      xbank: item.xbank,
      xktum: item.xktum,
      xusin: item.xusin,
      xutek: item.xutek,
      xiltm: item.xiltm,
      xgida: item.xgida,
      xtrzm: item.xtrzm,
      xsgrt: item.xsgrt,
      xindex: item.xindex,
      circle: item.circle,
    };
  }
  const _getMarkets = async () => {
    // funcIsProcessing(true, "_getMarkets");

    let counter = 5;
    while (true) {
      try {
        const urlApiDetay = `${urlApi}markets.php?_=${timestamp}`;
        const formationResponse = await axios.get(urlApiDetay);
        var dataDummy = [];
        dataDummy.push({
          items: formationResponse.data.data.map(marketCallback),
        });

        // // console.log("markets completed: ", dataDummy[0]);
        setMarkets(dataDummy[0]);
        let newMarket = [];
        let newValue = 1;
        newMarket = _.filter(dataDummy[0].items, function (o) {
          return o.exchange_id == newValue;
        });
        setMarketler(newMarket);
        setSearchMarket(newMarket);
        // funcIsProcessing(false);
        break;
      } catch (error) {
        if (counter === 0) {
          // console.log("markets network error: ", error);
          // funcIsProcessing(false);
          break;
        }
        counter--;
        continue;
      }
    }
  };
  function turkceIngilizceBuyukHarfCevir(metin) {
    const turkceHarfler = "çÇöÖıİşŞğĞüÜ";
    const ingilizHarfler = "ccooiissgguu";

    for (let i = 0; i < turkceHarfler.length; i++) {
      metin = metin.replace(
        new RegExp(turkceHarfler[i], "g"),
        ingilizHarfler[i]
      );
    }

    return metin.toUpperCase();
  }
  const _searchSembol = (text) => {
    funcIsProcessing(true, "_searchSembol");
    let newMarkets = searchMarket;
    if (text) {
      newMarkets = _.filter(newMarkets, function (o) {
        return o.label.includes(turkceIngilizceBuyukHarfCevir(text));
      });
    }
    setSearch(text);
    setMarketler(newMarkets);
    funcIsProcessing(false);
  };
  const _getPeriyots = async () => {
    // funcIsProcessing(true, "_getPeriyots");

    let counter = 5;
    while (true) {
      try {
        const urlApiDetay = `${urlApi}intervals.php?_=${timestamp}`;
        const formationResponse = await axios.get(urlApiDetay);
        var dataDummy = [];
        dataDummy.push({
          items: formationResponse.data.data.map(periyotCallback),
        });

        // // console.log("periyots completed: ", dataDummy[0]);
        setPeriyots(dataDummy[0]);
        localStorage.setItem("periyots", JSON.stringify(dataDummy[0]));
        // funcIsProcessing(false);
        break;
      } catch (error) {
        if (counter === 0) {
          // console.log("periyots network error: ", error);
          // funcIsProcessing(false);
          break;
        }
        counter--;
        continue;
      }
    }
  };

  const handleFormation = async (selectedSembol) => {
    funcIsProcessing(true, "handleFormation");
    if (selectedPeriyot.length == 0) {
      toast.error("Lütfen önce periyot seçiniz.");
      return false;
    }
    if (selectedSembol == "All") {
      let filteredData = updatedDataFormations;
      if (user.bayz == 0) {
        filteredData = updatedDataFormations.filter((item) => item.sort > 3);
      }
      console.log("filteredData", filteredData);
      let nameValues = filteredData.map((item) => item.slug);
      setFormationLists(nameValues);
    } else if (selectedSembol == "None") {
      setFormationLists([]);
    } else {
      console.log("selectedSembol", selectedSembol);
      if (formationLists.includes(selectedSembol)) {
        setFormationLists(
          formationLists.filter((item) => item !== selectedSembol)
        );
      } else {
        // console.log("aa", formationLists, selectedSembol);
        setFormationLists([...formationLists, selectedSembol]);
      }
    }
    funcIsProcessing(false);
    handleFiltreFormation(selectedSembol);
  };

  const handleFiltreFormation = async (newValue) => {
    console.log("handleFiltreFormation", newValue);
    let filtreValues = [];
    if (newValue == "All") {
      let filteredData = updatedDataFormations;
      if (user.bayz == 0) {
        filteredData = updatedDataFormations.filter((item) => item.sort > 3);
      }
      let nameValues = filteredData.map((item) => item.slug);

      filtreValues = addPropertiesToItems(
        nameValues,
        selectedPeriyot,
        selectedYon
      );
    } else if (newValue == "None") {
      filtreValues = [];
    } else {
      filtreValues = filtreFormationLists;
      let thisAyi = newValue == "OBO" ? 1 : 0;
      let thisBoga = thisBogaLists.findIndex((item) => item === newValue);
      const bayzIndex = filtreValues.findIndex(
        (item) => item.value === newValue
      );

      if (bayzIndex !== -1) {
        filtreValues.splice(bayzIndex, 1);
      } else {
        filtreValues.push({
          periyot: selectedPeriyot,
          yon: thisAyi == 1 ? "AYI" : thisBoga != -1 ? "BOĞA" : selectedYon,
          value: newValue,
          bayz_just_closed:
            newValue == "BAYZ" && bayzJustClosed == 1 ? "1" : "0",
        });
      }
    }
    console.log("filtreValues", filtreValues);
    _setFiltreValues(filtreValues);
  };
  const handleHacim = (selectedSembol) => {
    // console.log("selectedSembol", selectedSembol);
    if (selectedSembol == "All") {
      let nameValues = updatedDataHacim.map((item) => item.slug);
      setHacimLists(nameValues);
    } else if (selectedSembol == "None") {
      setHacimLists([]);
    } else {
      if (hacimLists.includes(selectedSembol)) {
        setHacimLists(hacimLists.filter((item) => item !== selectedSembol));
      } else {
        setHacimLists([...hacimLists, selectedSembol]);
      }
    }
    handleFiltreHacim(selectedSembol);
  };

  const handleFiltreHacim = async (newValue) => {
    // console.log("value", newValue);
    let filtreValues = [];
    if (newValue == "All") {
      let nameValues = updatedDataHacim.map((item) => item.slug);
      filtreValues = addPropertiesToItems(nameValues, "Günlük", selectedYon);
    } else if (newValue == "None") {
      filtreValues = [];
    } else {
      filtreValues = filtreHacimLists;

      const bayzIndex = filtreValues.findIndex(
        (item) => item.value === newValue
      );

      if (bayzIndex !== -1) {
        filtreValues.splice(bayzIndex, 1);
      } else {
        filtreValues.push({
          periyot: "Günlük",
          yon: selectedYon,
          value: newValue,
        });
      }
    }
    // console.log("filtreValues", filtreValues);

    _setFiltreHacim(filtreValues);
  };

  const handleIndicator = (selectedSembol) => {
    // console.log("selectedSembol", selectedSembol);
    if (selectedSembol == "All") {
      let nameValues = updatedDataIndicators.map((item) => item.slug);
      setIndicatorLists(nameValues);
    } else if (selectedSembol == "None") {
      setIndicatorLists([]);
    } else {
      if (indicatorLists.includes(selectedSembol)) {
        setIndicatorLists(
          indicatorLists.filter((item) => item !== selectedSembol)
        );
      } else {
        setIndicatorLists([...indicatorLists, selectedSembol]);
      }
    }
    handleFiltreIndicator(selectedSembol);
  };

  const handleFiltreIndicator = async (newValue) => {
    // console.log("value", newValue);
    let filtreIndicator = [];
    if (newValue == "All") {
      let nameValues = updatedDataIndicators.map((item) => item.slug);
      filtreIndicator = addPropertiesToItems(nameValues, "Günlük", selectedYon);
    } else if (newValue == "None") {
      filtreIndicator = [];
    } else {
      filtreIndicator = filtreIndicatorLists;

      const bayzIndex = filtreIndicator.findIndex(
        (item) => item.value === newValue
      );

      if (bayzIndex !== -1) {
        filtreIndicator.splice(bayzIndex, 1);
      } else {
        filtreIndicator.push({
          periyot: "Günlük",
          yon: selectedYon,
          value: newValue,
        });
      }
    }
    // console.log("filtreIndicator", filtreIndicator);

    _setFiltreIndicator(filtreIndicator);
  };

  const handleOrtalama = (selectedSembol) => {
    // console.log("selectedSembol", selectedSembol);
    if (selectedSembol == "All") {
      let nameValues = updatedDataOrtalamalar.map((item) => item.slug);
      setOrtalamaLists(nameValues);
    } else if (selectedSembol == "None") {
      setOrtalamaLists([]);
    } else {
      if (ortalamaLists.includes(selectedSembol)) {
        setOrtalamaLists(
          ortalamaLists.filter((item) => item !== selectedSembol)
        );
      } else {
        setOrtalamaLists([...ortalamaLists, selectedSembol]);
      }
    }
    handleFiltreOrtalama(selectedSembol);
  };

  const handleFiltreOrtalama = async (newValue) => {
    // console.log("value", newValue);
    let filtreOrtalama = [];
    if (newValue == "All") {
      let nameValues = updatedDataOrtalamalar.map((item) => item.slug);
      filtreOrtalama = addPropertiesToItems(nameValues, "Günlük", selectedYon);
    } else if (newValue == "None") {
      filtreOrtalama = [];
    } else {
      filtreOrtalama = filtreOrtalamaLists;

      const bayzIndex = filtreOrtalama.findIndex(
        (item) => item.value === newValue
      );

      if (bayzIndex !== -1) {
        filtreOrtalama.splice(bayzIndex, 1);
      } else {
        filtreOrtalama.push({
          periyot: "Günlük",
          yon: selectedYon,
          value: newValue,
        });
      }
    }
    // console.log("filtreOrtalama", filtreOrtalama);

    _setFiltreOrtalama(filtreOrtalama);
  };
  function addPropertiesToItems(filterFormationLists, periyot, yon) {
    return filterFormationLists.map((item) => {
      const value = item; // item'ı direkt olarak value olarak kullanıyoruz
      let thisAyi = value == "OBO" ? 1 : 0;
      let thisBoga = thisBogaLists.findIndex((item) => item === value);
      return {
        periyot,
        yon: thisAyi == 1 ? "AYI" : thisBoga != -1 ? "BOĞA" : yon,
        value,
        bayz_just_closed: value == "BAYZ" && bayzJustClosed == 1 ? "1" : "0",
      };
    });
  }

  const _setFiltreValues = (filtreValues) => {
    funcIsProcessing(true, "_setFiltreValues");
    setFiltreFormationLists(filtreValues);
    setUpdatedDataFormations(updatedDataFormations);
    localStorage.setItem("filtreFormationLists", JSON.stringify(filtreValues));
    _setFiltreFormationLists2();
    funcIsProcessing(false);
  };
  const _setFiltreHacim = (filtreHacim) => {
    setFiltreHacimLists(filtreHacim);
    // console.log("filtreHacim json", JSON.stringify(filtreHacim));
    setUpdatedDataFormations(updatedDataFormations);
    localStorage.setItem("filtreHacimLists", JSON.stringify(filtreHacim));
  };
  const _setFiltreIndicator = (filtreIndicator) => {
    setFiltreIndicatorLists(filtreIndicator);
    // console.log("filtreIndicator json", JSON.stringify(filtreIndicator));
    setUpdatedDataFormations(updatedDataFormations);
    localStorage.setItem(
      "filtreIndicatorLists",
      JSON.stringify(filtreIndicator)
    );
  };
  const _setFiltreOrtalama = (filtreOrtalanma) => {
    // console.log("filtreOrtalanma json", JSON.stringify(filtreOrtalanma));
    setUpdatedDataFormations(updatedDataFormations);
    localStorage.setItem(
      "filtreOrtalamaLists",
      JSON.stringify(filtreOrtalanma)
    );
    setFiltreOrtalamaLists(filtreOrtalanma);
  };
  async function _getIndicators() {
    funcIsProcessing(true, "_getIndicators");
    let date = new Date();
    let timestamp = date.getTime();
    const postData = {
      access_token: user.access_token,
      exchange_id: selectedBorsaId,
    };
    let res = await postDataWithHeaders(
      INDICATORS + `?_=${timestamp}`,
      postData,
      dispatch
    );
    if (res.status === 200) {
      // test for status you want, etc
      //// console.log(res.status);
    }
    // Don't forget to return something
    if (res.code === 0) {
      let resData = res.data;
      //// console.log("resData Indicators", resData);
      // // console.log(
      //   "resData[0].indicators.length)",
      //   resData[0].indicators.length
      // );
      localStorage.setItem("dataIndicator", JSON.stringify(resData));
      localStorage.setItem("stratejiAdet", resData[0].indicators.length);
      setDataIndicator(resData);
      setStratejiAdet(resData[0].indicators.length);
      funcIsProcessing(false);
    }
  }
  const _getDataFormations = async () => {
    funcIsProcessing(true, "_getDataFormations");
    setVisibleFiltre(false);
    let date = new Date();
    let timestamp = date.getTime();
    setSonucYok(true);
    setDataFormation(null);

    // setDataBody(null);
    // setDataBody2(null);
    const postData = {
      access_token: user.access_token,
      exchange_id: selectedBorsaId,
      bayz_just_closed: 0,
    };
    let res = await postDataWithHeaders(
      TARAMAV3 + `?_=${timestamp}`,
      postData,
      dispatch
    );
    if (res.status === 200) {
      // test for status you want, etc
      //// console.log(res.status);
    }
    // Don't forget to return something
    if (res.code === 0) {
      let resData = [];
      resData = _.groupBy(res.data.data, function (n) {
        return n.stock_code;
      });
      console.log("resData", resData);
      setDataFormation(resData);
      // localStorage.setItem("dataFormation", JSON.stringify(resData));

      let dataIndicator = localStorage.getItem("dataIndicator");
      // console.log("dataIndicator", dataIndicator);
      let filterDatas = [];
      if (dataIndicator) {
        let resDataIndicator = JSON.parse(dataIndicator);
        await Promise.all(
          resDataIndicator.map((dataInd, ix) => {
            let bull = 0;
            let bear = 0;
            let dataInd_heikin = null;
            let dataInd_heikin_1w = null;
            let dataInd_heikin_cmf = null;
            let dataInd_heikin_1w_cmf = null;
            let formations = [];
            if (resData[dataInd.stock_code]) {
              formations = resData[dataInd.stock_code];
              dataInd_heikin = resData[dataInd.stock_code][0].heikin;
              dataInd_heikin_1w = resData[dataInd.stock_code][0].heikin_1w;
              formations = resData[dataInd.stock_code];
              dataInd_heikin_cmf = resData[dataInd.stock_code][0].heikin_cmf;
              dataInd_heikin_1w_cmf =
                resData[dataInd.stock_code][0].heikin_1w_cmf;
              bull = countMarket(resData[dataInd.stock_code]).BULL
                ? countMarket(resData[dataInd.stock_code]).BULL
                : countMarket(resData[dataInd.stock_code]).BEAR
                ? countMarket(resData[dataInd.stock_code]).BEAR * -1
                : 0;
              bear = countMarket(resData[dataInd.stock_code]).BEAR
                ? countMarket(resData[dataInd.stock_code]).BEAR
                : 0;
            }
            formations.length > 0 &&
              filterDatas.push({
                stock_id: dataInd.stock_id,
                name: dataInd.stock_code,
                exchange_id: dataInd.exchange_id,
                puan: dataInd.puan,
                heikin: dataInd_heikin,
                heikin_1w: dataInd_heikin_1w,
                heikin_cmf: dataInd_heikin_cmf,
                heikin_1w_cmf: dataInd_heikin_1w_cmf,
                bull: bull,
                bear: bear,
                indicators: dataInd.indicators,
                formations: formations,
              });
          })
        );
      }

      console.log("filterDatas", filterDatas);
      setDataFilter(filterDatas);
      setDataBody2([filterDatas]);
      // setDataBody([filterDatas]);
      // localStorage.setItem("dataFilter", JSON.stringify(filterDatas));

      let hisseAdet = objectLength(resData);
      let taramaAdet = Object.values(resData).reduce(
        (count, current) => count + current.length,
        0
      );
      //// console.log(hisseAdet, taramaAdet);
      setTaramaAdet(taramaAdet);
      setHisseAdet(hisseAdet);
      setLoadFilter(true);
      funcIsProcessing(false);
    } else {
      funcIsProcessing(false);
      toast.error(res.data);
      setSonucYok(false);
      if (res.code === "-1") {
        // setInterval(() => {
        //   window.location.href = "/uye-cikis";
        // }, 2000);
      }
    }
  };
  const handleShowGrafik = (formation_id, loadChartType) => {
    if (user.email != "tradingview@bayz.app") {
      setModalGrafik(true);
      // //// console.log(formation_id, loadChartType)
      setFormationId(formation_id);
      setLoadChart(loadChartType);
    }
  };
  const handleButton = (fMarket) => {
    let newColor = "default";
    if (fMarket == "BULL") {
      newColor = "success";
    } else {
      newColor = "danger";
    }
    return newColor;
  };

  const handleYonToast = (value, ayi, boga) => {
    let message = "";
    if (ayi == 0) {
      message = `${value} formasyonunda sadece "AYI" yönü seçilebilir.`;
    } else {
      message = `${value} formasyonunda sadece "BOĞA" yönü seçilebilir.`;
    }
    toast.error(message);
  };

  const handleShowPuan = (hisseName) => {
    setModalIndicator(true);
    // //// console.log(hisseName)
    setPuanName(hisseName);
  };
  const handleName = (name) => {
    return _.filter(updatedDataFormations, { slug: name }).map((v) => v.label);
  };
  const filterDataBody = (dataBody, values) => {
    return _.filter(dataBody, (item) => {
      return _.includes(values, item.name);
    });
  };
  const _setVisibleSembolFunc = async () => {
    setVisibleSembol(true);
    //setLoadData(true);
  };
  const countFormationArray = (filteredData) => {
    return _.sumBy(filteredData, "formations.length");
  };
  const funcSelectReset = () => {
    funcIsProcessing(true, "funcSelectReset");

    setSelectedFormation([]);
    setFiltreFormationLists([]);
    setFiltreHacimLists([]);
    setFiltreOrtalamaLists([]);
    setFiltreIndicatorLists([]);
    setOrtalamaLists([]);
    setHacimLists([]);
    setFormationLists([]);
    setIndicatorLists([]);
    setBayzJustClosed(0);

    localStorage.setItem("formationLists", JSON.stringify([]));
    localStorage.setItem("ortalamaLists", JSON.stringify([]));
    localStorage.setItem("indicatorLists", JSON.stringify([]));
    localStorage.setItem("hacimLists", JSON.stringify([]));
    localStorage.setItem("selectedFormation", JSON.stringify([]));
    localStorage.setItem("filtreFormationLists", JSON.stringify([]));
    localStorage.setItem("filtreHacimLists", JSON.stringify([]));
    localStorage.setItem("filtreOrtalamaLists", JSON.stringify([]));
    localStorage.setItem("filtreIndicatorLists", JSON.stringify([]));
    setAyrimi(0);
    setTaramaAdet(0);
    setHisseAdet(0);
    setSonucYok(false);
    funcIsProcessing(false);
  };
  const resetFiltre = () => {
    setSelectedFormation([]);
    setFiltreFormationLists([]);
    setFiltreHacimLists([]);
    setFiltreOrtalamaLists([]);
    setFiltreIndicatorLists([]);
    setOrtalamaLists([]);
    setHacimLists([]);
    setFormationLists([]);
    setIndicatorLists([]);
    setBayzJustClosed(0);

    localStorage.setItem("formationLists", JSON.stringify([]));
    localStorage.setItem("ortalamaLists", JSON.stringify([]));
    localStorage.setItem("indicatorLists", JSON.stringify([]));
    localStorage.setItem("hacimLists", JSON.stringify([]));
    localStorage.setItem("selectedFormation", JSON.stringify([]));
    localStorage.setItem("filtreFormationLists", JSON.stringify([]));
    localStorage.setItem("filtreHacimLists", JSON.stringify([]));
    localStorage.setItem("filtreOrtalamaLists", JSON.stringify([]));
    localStorage.setItem("filtreIndicatorLists", JSON.stringify([]));
    setAyrimi(0);
    timeout(2000);
    // console.log("_getDataFormations, resetFiltre");
    setDataBody(null);
    setDataBody2(null);
    // _getDataFormations();
  };
  const funcSelectSymbols = () => {
    setLoadData(true);
    funcIsProcessing(true, "funcSelectSymbols");
    // // console.log("selectedSembol", selectedSembol);
    // console.log("dataBody", dataBody[0]);
    const filteredData = filterDataBody(dataBody[0], selectedSembol);
    timeout(1000);
    // console.log("filteredData", dataBody, filteredData);
    setDataBody([filteredData]);
    let hisseAdet = objectLength(filteredData);
    let taramaAdet = countFormationArray(filteredData);
    // // console.log(hisseAdet, taramaAdet);
    setTaramaAdet(taramaAdet);
    setHisseAdet(hisseAdet);
    setVisibleSembol(false);
    setSonucYok(true);
    setLoadData(false);
    timeout(1000);
    funcIsProcessing(false);
  };

  const _getDataName = (data, slug) => {
    return _.filter(data, { slug: slug }).map((v) => v.label);
  };
  const fechtMarkets = async () => {
    if (loadMarket == 0) {
      // console.log("fechtMarkets");
      setLoadMarket(1);
      await _getMarkets();
      await _getPeriyots();
    }
  };

  useEffect(() => {
    fechtMarkets();
  }, [loadMarket]);
  // useEffect(() => {
  //   if (hisseAdet != 0) {
  //     console.log("hisseAdet", hisseAdet);
  //     funcSelectSonuc();
  //   }
  // }, [hisseAdet]);
  // useEffect(() => {
  //   if (marketler.length > 0 && dataBody != null) {
  //     console.log("marketler.length > 0 && dataBody != null");
  //     funcSelectSonuc();
  //   }
  // }, [marketler]);
  const _getFormations = async () => {
    if (bayzJustClosed == 1 && filtreFormationLists.length > 0) {
      let setError = 0;
      await Promise.all(
        filtreFormationLists.map(async (formasyon) => {
          if (formasyon.value == "BAYZ") {
            setError = 1;
          }
        })
      );
      if (setError == 0) {
        toast.error("Bayz üstü ilk kapanış seçtiğiniz halde BAYZ seçmediniz!");
        return;
      }
    }

    await _getIndicators();
    await _getDataFormations();
  };
  useEffect(() => {
    _getIndicators();
  }, []);

  const filtre2item = async (item, periyot) => {
    let returnPeriyot = await handlePeiods(periyot);
    let searchData = {};
    // console.log("item.yon", item.yon);
    if (item.yon == "TÜMÜ") {
      searchData = {
        formation: handleFormasyon(item.value)[0].slug,
        period: returnPeriyot,
        bayz_just_closed: item.bayz_just_closed,
      };
    } else {
      searchData = {
        formation: handleFormasyon(item.value)[0].slug,
        market: item.yon == "BOĞA" ? "BULL" : "BEAR",
        period: returnPeriyot,
        bayz_just_closed: item.bayz_just_closed,
      };
    }
    return searchData;
  };
  const _setFiltreFormationLists2 = () => {
    localStorage.setItem(
      "filtreFormationLists",
      JSON.stringify(filtreFormationLists)
    );
    console.log("_setFiltreFormationLists2", filtreFormationLists);
    const filtre2 = [];
    filtreFormationLists.map(async (item) => {
      let searchData = {};
      console.log("item", item);
      item.periyot.map(async (periyot) => {
        searchData = await filtre2item(item, periyot);
        filtre2.push(searchData);
      });
      // let searchData = await filtre2item(item);
    });
    console.log("filtre2", filtre2);
    setFiltreFormationLists2(filtre2);

    setUpdatedDataFormations(updatedDataFormations);
  };

  const periyotValueConvert = (fperiyots) => {
    const returnPeriyot = [];
    for (const per of fperiyots.periyot) {
      const filteredItems = periyots.items.filter((v) => v.label === per);
      if (filteredItems.length > 0) {
        returnPeriyot.push(filteredItems[0].value);
      }
    }

    // Join işlemi için array kontrolü
    if (returnPeriyot.length === 0) {
      return "";
    }

    return returnPeriyot.join(", ");
  };

  useEffect(() => {
    timeout(2000);
    // console.log("2sn");
    // console.log("filtreFormationLists change", filtreFormationLists);
    // _setFiltreFormationLists2();
  }, [filtreFormationLists]);
  useEffect(() => {
    timeout(2000);
    // console.log("2sn");
    // console.log("filtreHacimLists change", filtreHacimLists);
    localStorage.setItem("filtreHacimLists", JSON.stringify(filtreHacimLists));
    // setUpdatedDataFormations(updatedDataFormations);
  }, [filtreHacimLists]);
  useEffect(() => {
    timeout(2000);
    // console.log("2sn");
    // console.log("filtreIndicatorLists change", filtreIndicatorLists);
    localStorage.setItem(
      "filtreIndicatorLists",
      JSON.stringify(filtreIndicatorLists)
    );
    // setUpdatedDataFormations(updatedDataFormations);
  }, [filtreIndicatorLists]);
  useEffect(() => {
    timeout(2000);
    // console.log("2sn");
    // console.log("filtreOrtalamaLists change", filtreOrtalamaLists);
    localStorage.setItem(
      "filtreOrtalamaLists",
      JSON.stringify(filtreOrtalamaLists)
    );
    // setUpdatedDataFormations(updatedDataFormations);
  }, [filtreOrtalamaLists]);
  useEffect(() => {
    timeout(2000);
    // console.log("2sn");
    // console.log("ortalamaLists change", ortalamaLists);
    localStorage.setItem("ortalamaLists", JSON.stringify(ortalamaLists));
    // setUpdatedDataFormations(updatedDataFormations);
  }, [ortalamaLists]);
  useEffect(() => {
    timeout(2000);
    // console.log("2sn");
    // console.log("hacimLists change", hacimLists);
    localStorage.setItem("hacimLists", JSON.stringify(hacimLists));
    // setUpdatedDataFormations(updatedDataFormations);
  }, [hacimLists]);
  useEffect(() => {
    timeout(2000);
    // console.log("2sn");
    // console.log("indicatorLists change", indicatorLists);
    localStorage.setItem("indicatorLists", JSON.stringify(indicatorLists));
    // setUpdatedDataFormations(updatedDataFormations);
  }, [indicatorLists]);
  useEffect(() => {
    timeout(2000);
    // console.log("2sn");
    console.log("formationLists change", formationLists);
    localStorage.setItem("formationLists", JSON.stringify(formationLists));
    // setUpdatedDataFormations(updatedDataFormations);
  }, [formationLists]);

  useEffect(() => {
    console.log("filtreFormationLists useEffect *", filtreFormationLists);
    _setFiltreValues(filtreFormationLists);
  }, [filtreFormationLists]);
  useEffect(() => {
    console.log("selectedPeriyot useEffect *", selectedPeriyot);
  }, [selectedPeriyot]);

  useEffect(() => {
    timeout(2000);
    // console.log("2sn");
    console.log("selectedPeriyot change", selectedPeriyot);
    // localStorage.setItem("selectedPeriyot", JSON.stringify(selectedPeriyot));
    // setUpdatedDataFormations(updatedDataFormations);
  }, [selectedPeriyot]);
  useEffect(() => {
    // console.log(bayzJustClosed, updatedDataFormations);
    setUpdatedDataFormations(updatedDataFormations);
  }, [bayzJustClosed, updatedDataFormations]);
  useEffect(() => {
    console.log("filtreFormationLists useEffect", filtreFormationLists);
  }, [filtreFormationLists]);
  // useEffect(() => {
  //   if (dataBody2 && periyots) {
  //     // console.log("yeniden çalıştı");
  //     // console.log(
  //     //   "first",
  //     //   filtreFormationLists.length,
  //     //   filtreHacimLists.length,
  //     //   filtreIndicatorLists.length,
  //     //   filtreOrtalamaLists.length
  //     // );
  //     if (
  //       filtreFormationLists.length > 0 ||
  //       filtreHacimLists.length > 0 ||
  //       filtreIndicatorLists.length > 0 ||
  //       filtreOrtalamaLists.length > 0
  //     ) {
  //       console.log("oldu mu");
  //       _sonucGetir();
  //     }
  //   }
  // }, [dataBody2, periyots]);
  useEffect(() => {
    // console.log("selectedBorsaId", selectedBorsaId, dataBody2);
    if (dataBody2 != null) {
      // console.log("funcSelectSonuc", dataBody2);
      funcSelectSonuc();
    }
  }, [dataBody2]);

  useEffect(() => {
    document.title = "Ana Kumanda - Sirius Algo";
  }, []);
  return (
    <>
      <Row>
        <div className="DashBoard_container">
          <div className="AramaPaneli">
            <div
              className="AramaSelect active"
              onClick={() => setVisibleBorsa(true)}
            >
              <div className="AramaSelect__body">
                <div className="AramaSelect__title">Borsa Seç</div>
                <div className="AramaSelect__text">
                  {selectedBorsa ? selectedBorsa : "Henüz Seçilmedi"}
                </div>
              </div>
            </div>
            <div
              className="AramaSelect active"
              onClick={() => _setVisibleSembolFunc(true)}
            >
              <div className="AramaSelect__body">
                <div className="AramaSelect__title">Sembol Seç</div>
                <div className="AramaSelect__text">
                  {selectedSembol.length > 0
                    ? selectedSembol.length - 1 > 0
                      ? selectedSembol[0] + `, + ${selectedSembol.length - 1}`
                      : selectedSembol[0]
                    : "Henüz Seçilmedi"}
                </div>
              </div>
            </div>
            <div
              className={selectedBorsa ? "AramaSelect active" : "AramaSelect"}
              onClick={() => (selectedBorsa ? setVisiblePeriyot(true) : null)}
            >
              <div className="AramaSelect__body">
                <div className="AramaSelect__title">Periyot Seç</div>
                <div className="AramaSelect__text">
                  {selectedPeriyot.length > 0
                    ? selectedPeriyot.join(", ")
                    : "Henüz Seçilmedi"}
                </div>
              </div>
            </div>
            <div
              className={selectedBorsa ? "AramaSelect active" : "AramaSelect"}
              onClick={() => (selectedBorsa ? setVisibleYon(true) : null)}
            >
              <div className="AramaSelect__body">
                <div className="AramaSelect__title">Yön Seç</div>
                <div className="AramaSelect__text">
                  {selectedYon ? selectedYon : "Henüz Seçilmedi"}
                </div>
              </div>
            </div>
            <div
              className={selectedPeriyot ? "AramaSelect active" : "AramaSelect"}
              onClick={() => (selectedPeriyot ? _setVisibleFiltre() : null)}
            >
              <div className="AramaSelect__body">
                <div className="AramaSelect__title">Filtre Seç</div>
                <div className="AramaSelect__text">
                  {filtreFormationLists.length > 0 ||
                  filtreHacimLists.length > 0 ||
                  filtreIndicatorLists.length > 0 ||
                  filtreOrtalamaLists.length > 0
                    ? "Filtreleme Seçildi"
                    : "Henüz Seçilmedi"}
                </div>
              </div>
            </div>
            {(filtreFormationLists.length > 0 ||
              filtreHacimLists.length > 0 ||
              filtreIndicatorLists.length > 0 ||
              filtreOrtalamaLists.length > 0) && (
              <div
                onClick={() => _getFormations()}
                className="AramaSelect sonucGetirBtn2 cursorPointer"
                style={{ opacity: 1 }}
              >
                {isProcessing ? (
                  <PulseLoader
                    color="#fff"
                    aria-label="Sonuçlar Getiriliyor..."
                  />
                ) : (
                  <div className="AramaSelect__title">
                    <HiRefresh size={24} /> {"Sonuç Getir"}
                  </div>
                )}
              </div>
            )}
            {/* <div
                  className="AramaToplu"
                  onClick={() => funcListeTopluEkle()}
                >
                  <TopluEkle />
                  <div className="AramaToplu__text">Toplu Listeye Ekle</div>
                </div> */}
          </div>
        </div>
      </Row>
      <Row>
        <Col lg={12} className="AnaKumanda__container">
          {isProcessing && (
            <div style={{ width: "100%" }}>
              <div className="AnaKumanda__sonuc">
                <div className="AnaKumanda__sonucyok">
                  <PulseLoader
                    color={theme == "dark" ? "#fff" : "#000"}
                    aria-label="Sonuçlar Getiriliyor..."
                  />
                  <div className="AnaKumanda__sonucyok__text">
                    Sonuçlar yükleniyor...
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!dataBody ? (
            <>
              <div className="AnaKumanda__sonuc">
                <div className="AnaKumanda__sonucyok">
                  <AnaKumandaSonucYok />
                  <div className="AnaKumanda__sonucyok__text">
                    Henüz arama yapmadınız. <br />
                    hemen borsa seçerek Sirius’un dünyasını keşfetmeye başlayın.
                    <br />
                    <span>Hazırsanız uzaya çıkıyoruz!✨</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="AnaKumanda__filtre">
                <div
                  className="AnaKumanda__filtre__click"
                  onClick={() => _setVisibleFiltre()}
                >
                  {selectedBorsa && (
                    <div className="AnaKumanda__filtre__body">
                      <div className="AnaKumanda__filtre__title">Borsa</div>
                      <div className="AnaKumanda__filtre__text">
                        {selectedBorsa}
                      </div>
                    </div>
                  )}
                  {selectedSembol.length > 0 && (
                    <div className="AnaKumanda__filtre__body">
                      <div className="AnaKumanda__filtre__title">Semboller</div>
                      <div className="AnaKumanda__filtre__text">
                        {selectedSembol.length - 1 > 0
                          ? selectedSembol[0] +
                            `, + ${selectedSembol.length - 1}`
                          : selectedSembol[0]}
                      </div>
                    </div>
                  )}
                  {filtreFormationLists.length > 0 && (
                    <>
                      <div className="AnaKumanda__filtre__container">
                        <div className="AnaKumanda__filtre__top">Formasyon</div>
                        <div className="AnaKumanda__filtre__bottom">
                          <div
                            className="AnaKumanda__filtre__bottom__left"
                            onClick={() => _setVisibleFiltre()}
                          >
                            {handleName(filtreFormationLists[0].value)}{" "}
                            <span className="AnaKumanda__filtre__bottom__left__period">
                              {filtreFormationLists[0].periyot.join(" - ")}
                            </span>
                            {filtreFormationLists[0].yon == "BOĞA" ? (
                              <ArrowUp color="#5fb045" />
                            ) : filtreFormationLists[0].yon == "AYI" ? (
                              <ArrowDown color="#E12222" />
                            ) : (
                              <ArrowSort color="#9a9a9a" />
                            )}
                            <ButtonClose />
                          </div>
                          {filtreFormationLists.length - 1 > 0 && (
                            <div
                              className="AnaKumanda__filtre__bottom__right"
                              onClick={() => _setVisibleFiltre()}
                            >
                              +{filtreFormationLists.length - 1}
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <img src={araCizgi} />
                      </div>
                    </>
                  )}
                  {filtreHacimLists.length > 0 && (
                    <>
                      <div className="AnaKumanda__filtre__container">
                        <div className="AnaKumanda__filtre__top">Hacim</div>
                        <div className="AnaKumanda__filtre__bottom">
                          <div
                            className="AnaKumanda__filtre__bottom__left"
                            onClick={() => _setVisibleFiltre()}
                          >
                            {_getDataName(
                              updatedDataHacim,
                              filtreHacimLists[0].value
                            )}
                            {filtreHacimLists[0].yon == "BOĞA" ? (
                              <ArrowUp color="#5fb045" />
                            ) : filtreHacimLists[0].yon == "AYI" ? (
                              <ArrowDown color="#E12222" />
                            ) : (
                              <ArrowSort color="#9a9a9a" />
                            )}
                            <ButtonClose />
                          </div>
                          {filtreHacimLists.length > 1 && (
                            <div className="AnaKumanda__filtre__bottom__right">
                              +{filtreHacimLists.length - 1}
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <img src={araCizgi} />
                      </div>
                    </>
                  )}
                  {filtreOrtalamaLists.length > 0 && (
                    <>
                      <div className="AnaKumanda__filtre__container">
                        <div className="AnaKumanda__filtre__top">
                          Ortalamalar
                        </div>
                        <div className="AnaKumanda__filtre__bottom">
                          <div
                            className="AnaKumanda__filtre__bottom__left"
                            onClick={() => _setVisibleFiltre()}
                          >
                            {_getDataName(
                              updatedDataOrtalamalar,
                              filtreOrtalamaLists[0].value
                            )}
                            {filtreOrtalamaLists[0].yon == "BOĞA" ? (
                              <ArrowUp color="#5fb045" />
                            ) : filtreOrtalamaLists[0].yon == "AYI" ? (
                              <ArrowDown color="#E12222" />
                            ) : (
                              <ArrowSort color="#9a9a9a" />
                            )}
                            <ButtonClose />
                          </div>
                          {filtreOrtalamaLists.length > 1 && (
                            <div className="AnaKumanda__filtre__bottom__right">
                              +{filtreOrtalamaLists.length - 1}
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <img src={araCizgi} />
                      </div>
                    </>
                  )}
                  {filtreIndicatorLists.length > 0 && (
                    <>
                      <div className="AnaKumanda__filtre__container">
                        <div className="AnaKumanda__filtre__top">
                          İndikatörler
                        </div>
                        <div className="AnaKumanda__filtre__bottom">
                          <div
                            className="AnaKumanda__filtre__bottom__left"
                            onClick={() => _setVisibleFiltre()}
                          >
                            {_getDataName(
                              updatedDataIndicators,
                              filtreIndicatorLists[0].value
                            )}
                            {filtreIndicatorLists[0].yon == "BOĞA" ? (
                              <ArrowUp color="#5fb045" />
                            ) : filtreIndicatorLists[0].yon == "AYI" ? (
                              <ArrowDown color="#E12222" />
                            ) : (
                              <ArrowSort color="#9a9a9a" />
                            )}
                            <ButtonClose />
                          </div>
                          {filtreIndicatorLists.length > 1 && (
                            <div className="AnaKumanda__filtre__bottom__right">
                              +{filtreIndicatorLists.length - 1}
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <img src={araCizgi} />
                      </div>
                    </>
                  )}
                </div>
                <Button
                  variant="secondary"
                  onClick={() => _getFormations()}
                  className="sonucGetirBtn"
                >
                  {isProcessing ? (
                    <PulseLoader
                      color="#fff"
                      aria-label="Sonuçlar Getiriliyor..."
                    />
                  ) : (
                    <HiRefresh size={24} />
                  )}
                </Button>
                <div className="AnaKumanda__filtre__container_right gap24 flex-row ml-auto">
                  {filtreFormationLists.length > 0 ||
                  filtreHacimLists.length > 0 ||
                  filtreIndicatorLists.length > 0 ||
                  filtreOrtalamaLists.length > 0 ? (
                    <>
                      <FaFilterCircleXmark
                        color="red"
                        size={24}
                        onClick={() => resetFiltre()}
                      />

                      <SonucTekEkle onClick={() => setVisibleFormul(true)} />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="Sonuclar_container">
                <div className="Sonuclar_left">
                  <div className="Sonuclar_left__title">Sonuçlar</div>
                  <div className="Sonuclar_left__text">
                    {hisseAdet} {hisseType} - {taramaAdet} Formasyon
                  </div>
                </div>
                {hisseAdet != 0 && (
                  <div className="Sonuclar_right">
                    <div className="Sonuclar_right__container">
                      <div
                        className={
                          sortType == 2
                            ? "Sonuclar_right__sort active"
                            : "Sonuclar_right__sort"
                        }
                        onClick={() =>
                          sortType == 2
                            ? handleSortingChange(sortTypeChange, "puan")
                            : handleSortType(2)
                        }
                      >
                        Stratejiye Göre Sırala
                        {sortType == 2 && (
                          <div className="Sonuclar_right__sort__icon">
                            {sortTypeChange == "default" ? (
                              <ArrowSort
                                color={theme == "dark" ? "#fff" : "#000"}
                              />
                            ) : sortTypeChange == "up" ? (
                              <ArrowDown color="red" />
                            ) : (
                              <ArrowUp color="#5fb045" />
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          sortType == 1
                            ? "Sonuclar_right__sort active"
                            : "Sonuclar_right__sort"
                        }
                        onClick={() =>
                          sortType == 1
                            ? handleSortingChange(sortTypeChange, "bull")
                            : handleSortType(1)
                        }
                      >
                        Boğaya Göre Sırala
                        {sortType == 1 && (
                          <div className="Sonuclar_right__sort__icon">
                            {sortTypeChange == "default" ? (
                              <ArrowSort
                                color={theme == "dark" ? "#fff" : "#000"}
                              />
                            ) : sortTypeChange == "up" ? (
                              <ArrowDown color="red" />
                            ) : (
                              <ArrowUp color="#5fb045" />
                            )}
                          </div>
                        )}
                      </div>
                      {isOpenShift && (
                        <div
                          className={"Sonuclar_right__sort active success1"}
                          onClick={() => handleSortingHeikin()}
                        >
                          Heikin Göre Filtre
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="Sonuclar__body">
                {hisseAdet == 0 && (
                  <div style={{ width: "100%" }}>
                    <div className="AnaKumanda__sonuc">
                      <div className="AnaKumanda__sonucyok">
                        <AnaKumandaSonucYok />
                        <div className="AnaKumanda__sonucyok__text">
                          Sonuç bulunamadı. <br />
                          Lütfen ayarları kontrol ediniz!
                          <br />
                          <span>Hazırsanız uzaya çıkıyoruz!✨</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!isProcessing &&
                  dataBody.map((data, index) =>
                    Object.values(data).map((forms, indix) => {
                      let count = 0;

                      forms.formations &&
                        forms.formations.map((formArray, indx) => {
                          let isShow = false;

                          for (
                            let i = 0;
                            i < filtreFormationLists2.length;
                            i++
                          ) {
                            // Her iki koşulu da kontrol et
                            let item = filtreFormationLists2[i];
                            {
                              /* console.log("isShow 1", formArray, item); */
                            }
                            if (
                              item.market &&
                              formArray.formation == item.formation &&
                              formArray.period == item.period &&
                              formArray.market == item.market
                            ) {
                              if (
                                bayzJustClosed == 1 &&
                                item.formation == "BAYZ" &&
                                formArray.bayz_just_closed == 1
                              ) {
                                isShow = true;
                                formArray.show = 1;
                                break;
                              } else {
                                isShow = true;
                                formArray.show = 1;
                                break;
                              }
                            }
                            if (
                              !item.market &&
                              formArray.formation == item.formation &&
                              formArray.period == item.period
                            ) {
                              if (
                                bayzJustClosed == 1 &&
                                item.formation == "BAYZ" &&
                                formArray.bayz_just_closed == 1
                              ) {
                                isShow = true;
                                formArray.show = 1;
                                break;
                              } else {
                                isShow = true;
                                formArray.show = 1;
                                break;
                              }
                            }
                          }
                          {
                            /* console.log(
                            "filtreFormationLists2: ",
                            forms.name,
                            handleName(formArray.formation),
                            formArray.period,
                            formArray.market,
                            filtreFormationLists2,
                            isShow
                          ); */
                          }
                          {
                            /* let isShow = _.filter(filtreFormationLists2, {
                            function(item) {
                              // Her iki koşulu da kontrol et
                              let hasMarket = item.hasOwnProperty("market"); // "market" alanı var mı?
                              if (hasMarket) {
                                return {
                                  formation: formArray.formation,
                                  period: formArray.period,
                                  market: formArray.market,
                                };
                              } else {
                                return {
                                  formation: formArray.formation,
                                  period: formArray.period,
                                };
                              }
                            },
                          }); */
                          }

                          {
                            /* console.log("isShow", isShow); */
                          }

                          if (isShow) {
                            count = count + 1;
                            formArray.show = 1;
                          }
                          return count;
                        });
                      let showCount = forms.formations.length - count;
                      {
                        /* console.log("showCount", showCount, count); */
                      }
                      let exchange_id = forms.exchange_id;
                      let puan = forms.puan;

                      let formsName = forms.name.replace("/", "_") || "";
                      return (
                        <div key={indix} className="Sonuclar__body__container">
                          <div className="Sonuclar__body__container__left">
                            {/* <SelectIcon
                                hisseName={forms.name}
                                fStocks={topluEkleLists}
                                func={handleTopluEkle}
                              /> */}

                            <SonucTekEkle
                              className="Sonuclar__body__container__left__close"
                              onClick={() => funcListeEkle(forms)}
                            />
                            {/* <SonucAlarm
                                className="Sonuclar__body__container__left__alarm"
                                onClick={() => funcAlarmFiyat(forms.name)}
                              /> */}
                            <Link
                              to={`/grafik-paneli/${formsName}`}
                              target="_blank"
                            >
                              <SonucGrafik />
                            </Link>
                            <span className="Sonuclar__body__container__left__text">
                              {forms.name}
                            </span>
                            <div className="Sonuclar__body__container__left_border ml-auto"></div>
                          </div>

                          <div className="Sonuclar__body__container__right">
                            {forms.formations &&
                              forms.formations.map((formArray, indx) => {
                                let formasyonName = handleName(
                                  formArray.formation
                                );
                                let foundItem = null;

                                if (user.bayz == "0") {
                                  foundItem = zamansal.find(
                                    (element) => element === formasyonName[0]
                                  );
                                }

                                let icon =
                                  formArray.market === "BULL" ? (
                                    <BiSolidUpArrow color="#5fb045" />
                                  ) : (
                                    <BiSolidDownArrow color="#E12222" />
                                  );
                                let boxButton = handleButton(formArray.market);
                                let filePath = "";
                                {
                                  /* if (bayzClosed == 0 && bayzJustClosed == 1) {
                                  formArray.show = 0;
                                } */
                                }
                                let showFormation =
                                  formArray.show == 1 ? "d-block" : "d-none";
                                if (dahaGoster && dahaGosterId === forms.name) {
                                  showFormation = "d-block";
                                }
                                if (count == 0) {
                                  showFormation = "d-block";
                                }
                                {
                                  /* console.log("formArray", formArray); */
                                }
                                return (
                                  <Button
                                    key={indx}
                                    className={`Sonuclar__body__button iShow ${showFormation}  ${formArray.formation} btn-sm ${boxButton}`}
                                    onClick={() =>
                                      foundItem
                                        ? handleUyari(formasyonName[0])
                                        : handleShowGrafik(
                                            formArray.id,
                                            formArray.chart
                                          )
                                    }
                                    style={{
                                      marginLeft: 5,
                                      marginBottom: 5,
                                      // backgroundColor: `${handleColor(
                                      //   formArray.formationColor,
                                      //   formArray.formation,
                                      //   formArray.market
                                      // )}`,
                                    }}
                                  >
                                    {icon} {handleName(formArray.formation)}{" "}
                                    {formArray.bayz_percentage
                                      ? `(${formArray.bayz_percentage}%)`
                                      : ""}{" "}
                                    {`(${formArray.period})`}
                                  </Button>
                                );
                              })}
                          </div>
                          <div className="Sonuclar__body__container__right_count">
                            {count > 0 && (
                              <span
                                className="dahaGoster"
                                onClick={() => _setDahaGoster(forms.name)}
                              >
                                {dahaGosterId === forms.name
                                  ? "Gizle"
                                  : mobile
                                  ? `+${showCount}`
                                  : `${showCount} Adet daha var`}
                              </span>
                            )}
                          </div>
                          <div
                            className="Sonuclar__body__strateji"
                            onClick={() => handleShowPuan(forms.name)}
                          >
                            <span className="Sonuclar__body__strateji__text">
                              Strateji
                            </span>
                            <span className="Sonuclar__body__strateji__puan">
                              {puan}/{updateDataAll.length}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  )}
                <div style={{ textAlign: "center", marginTop: 20 }}>
                  <p>
                    BİST isim ve logosu "Koruma Marka Belgesi" altında
                    korunmakta olup izinsiz kullanılamaz, iktibas edilemez,
                    değiştirilemez. BİST ismi altında açıklanan tüm bilgilerin
                    telif hakları tamamen BİST'e ait olup, tekrar yayınlanamaz.
                    Veriler <img src={forinvest} width={90} /> tarafından
                    yayınlanmaktadır. Veriler <img src={forinvest} width={90} />{" "}
                    tarafından sağlanmaktadır. BİST hisse verileri 15 dakika
                    gecikmelidir.
                  </p>
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>

      {visibleBorsa && (
        <Modal
          show={visibleBorsa}
          onHide={handleBorsaClose}
          dialogClassName="modal-787w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Borsa Seç
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelectButtons">
              {dataBorsa.map((borsa) => (
                <Button
                  key={borsa.label}
                  className={
                    selectedBorsa == borsa.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() =>
                    funcSelectBorsa(
                      selectedBorsa == borsa.label ? "" : borsa.label,
                      borsa.value
                    )
                  }
                >
                  {borsa.label}
                  {selectedBorsa == borsa.label && (
                    <span className="modalSelectButton__close">
                      <ButtonClose />
                    </span>
                  )}
                </Button>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      )}

      {visibleSembol && (
        <Modal
          show={visibleSembol}
          onHide={handleSembolClose}
          dialogClassName="modal-787w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Sembol Seç
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSearch">
              <div className="modalSearch__input">
                <BsSearch color="#fff" />
                <input
                  type="text"
                  placeholder="Arama Yap"
                  value={search}
                  onChange={(e) => _searchSembol(e.target.value)}
                />
              </div>
            </div>
            <div className="modalSelecteds">
              {selectedSembol.length > 0 &&
                selectedSembol.map((sembol) => {
                  return (
                    <div
                      key={sembol}
                      className="modalSelectButton active"
                      onClick={() => funcSelectSembol(sembol)}
                    >
                      {sembol}
                      <span className="modalSelectButton__close">
                        <ButtonClose />
                      </span>
                    </div>
                  );
                })}
            </div>

            <div className="modalSelectButtons">
              {marketler.map((sembol) => (
                <Button
                  key={sembol.label}
                  className={
                    selectedSembol == sembol.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() => funcSelectSembol(sembol.label)}
                >
                  {sembol.label}
                </Button>
              ))}
            </div>
            <Button
              onClick={() => _getFormations()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Sonuçları Listele"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visiblePeriyot && (
        <Modal
          show={visiblePeriyot}
          onHide={handlePeriyotClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Periyot Seç
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelecteds">
              {selectedPeriyot.length > 0 &&
                selectedPeriyot.map((sembol) => {
                  return (
                    <div
                      key={sembol}
                      className="modalSelectButton active"
                      onClick={() => funcSelectPeriyot(sembol)}
                    >
                      {sembol}
                      <span className="modalSelectButton__close">
                        <ButtonClose />
                      </span>
                    </div>
                  );
                })}
            </div>
            <div className="modalSelectButtons">
              {periyots.items.map((period) => (
                <Button
                  key={period.label}
                  className={"modalSelectButton"}
                  onClick={() => funcSelectPeriyot(period.label)}
                >
                  {period.label}
                </Button>
              ))}
            </div>
            <Button
              onClick={() => funcSelectPeriyotSonuc()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Periyot Belirle"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleYon && (
        <Modal
          show={visibleYon}
          onHide={handleYonClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Yön Seç
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSelectButtons">
              <Button
                key={"BOĞA"}
                className={
                  selectedYon == "BOĞA"
                    ? "Sonuclar__body__button2 success active"
                    : "Sonuclar__body__button2 success"
                }
                onClick={() => funcSelectYon("BOĞA")}
              >
                {"BOĞA"}
              </Button>
              <Button
                key={"AYI"}
                className={
                  selectedYon == "AYI"
                    ? "Sonuclar__body__button2 danger active"
                    : "Sonuclar__body__button2 danger"
                }
                onClick={() => funcSelectYon("AYI")}
              >
                {"AYI"}
              </Button>
            </div>
            <Button
              onClick={() => funcSelectYonSonuc()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Yön Belirle"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleFiltre && (
        <Modal
          show={visibleFiltre}
          onHide={handleFiltreClose}
          dialogClassName="modal-65w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Filtre Seç
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalSubDiv">
              <h2 className="modalSubDiv__title">Formasyon</h2>
              <p className="modalSubDiv__text">
                Aşağıdan dilediğiniz formasyonları seçerek filtreleme işlemine
                devam ediniz.
              </p>
            </div>
            <div className="modalSelectDivUst">
              <div className="modalSelectDiv all">
                <div className="modalSelectDivTop">
                  <div className="modalSelectDiv__left mr-auto">
                    {formationLists.length == updatedDataFormationsLength ? (
                      <SonucSecildi
                        onClick={(e) => handleFormation("None", e)}
                      />
                    ) : (
                      <SonucSec onClick={(e) => handleFormation("All", e)} />
                    )}
                    Tümünü Seç
                  </div>
                </div>
              </div>
              <div className="modalSelectDiv all  ml-auto">
                <div className="modalSelectDivTop">
                  <div className="modalSelectDiv__right">
                    {user.bayz == 1 && (
                      <div className="modalSubDiv__footer__type">
                        {bayzJustClosed == 1 ? (
                          <SonucSecildi
                            onClick={() => handleJust(0, "bayzJustClosed")}
                          />
                        ) : (
                          <SonucSec
                            onClick={() => handleJust(1, "bayzJustNotClosed")}
                          />
                        )}
                        BayZ üstünde İlk Kapanış Yapanlar
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modalSelectDivs">
              {updatedDataFormations.map((formation, index) => {
                {
                  /* if (bayzJustClosed == 1 && !formation.slug.includes("BAYZ")) {
                  return null;
                } */
                }

                let thisFiltre = filtreFormationLists.findIndex(
                  (item) => item.value === formation.slug
                );

                let thisBoga = thisBogaLists.findIndex(
                  (item) => item === formation.slug
                );

                let thisAyi = formation.label == "OBO" ? 0 : 1;

                let thisYon =
                  thisFiltre == -1
                    ? selectedYon
                    : filtreFormationLists[thisFiltre].yon;

                let bayz =
                  user.bayz == 1
                    ? true
                    : user.bayz == 0 && index < 4
                    ? false
                    : true;
                let secilen = formationLists.some(
                  (item) => item === formation.slug
                );

                return bayz ? (
                  <div
                    key={index}
                    className={
                      periyotOpen == formation.label
                        ? "modalSelectDiv period"
                        : "modalSelectDiv"
                    }
                  >
                    <div
                      className={
                        secilen
                          ? "modalSelectDivTop secilen"
                          : "modalSelectDivTop"
                      }
                    >
                      <div className="modalSelectDiv__left">
                        {secilen ? (
                          <SonucSecildi
                            onClick={(e) => handleFormation(formation.slug, e)}
                          />
                        ) : (
                          <SonucSec
                            onClick={(e) => handleFormation(formation.slug, e)}
                          />
                        )}
                        {formation.label}
                      </div>
                      <div className="modalSelectDiv__right">
                        <div
                          className="modalSelectDiv__right__period"
                          onClick={() => handlePeriyotOpen(formation.label)}
                        >
                          <span>
                            {thisFiltre == -1
                              ? "Periyot"
                              : filtreFormationLists[thisFiltre].periyot.join(
                                  ", "
                                )}{" "}
                          </span>
                          <ArrowThinDown color="#9a9a9a" />
                        </div>
                        <div
                          className="modalSelectDiv__right__yon"
                          onClick={() =>
                            thisAyi == 0 || thisBoga != -1
                              ? handleYonToast(
                                  formation.label,
                                  thisAyi,
                                  thisBoga
                                )
                              : handleYonOpen(formation.label)
                          }
                        >
                          {thisAyi == 0 ? (
                            <ArrowDown color="#E12222" />
                          ) : thisBoga != -1 ? (
                            <ArrowUp color="#22DE2A" />
                          ) : thisYon == "AYI" ? (
                            <ArrowDown color="#E12222" />
                          ) : thisYon == "BOĞA" ? (
                            <ArrowUp color="#22DE2A" />
                          ) : (
                            <ArrowSort color="#9a9a9a" />
                          )}
                        </div>
                      </div>
                    </div>
                    {periyotOpen == formation.label && (
                      <div className="modalSelectDiv__Bottom">
                        {periyots.items.map((period) => {
                          let secilen =
                            filtreFormationLists.length > 0
                              ? filtreFormationLists[thisFiltre].periyot.some(
                                  (item) => item === period.label
                                )
                              : false;

                          return (
                            <div
                              key={period.value}
                              className={
                                secilen
                                  ? "modalSelectDiv__periyot__item active"
                                  : "modalSelectDiv__periyot__item"
                              }
                              onClick={() =>
                                handlePeriyotSample(
                                  formation.slug,
                                  period.label
                                )
                              }
                            >
                              {period.value}
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {yonOpen == formation.label && (
                      <div className="modalSelectDiv__Bottom">
                        {thisAyi == 0 ? (
                          <div
                            className="modalSelectDiv__periyot__item"
                            onClick={() => handleYon(formation.slug, "AYI")}
                          >
                            AYI
                          </div>
                        ) : thisBoga != -1 ? (
                          <div
                            className="modalSelectDiv__periyot__item"
                            onClick={() => handleYon(formation.slug, "BOĞA")}
                          >
                            BOĞA
                          </div>
                        ) : (
                          dataYon2.map((yon) => (
                            <div
                              key={yon.label}
                              className="modalSelectDiv__periyot__item"
                              onClick={() =>
                                handleYon(formation.slug, yon.label)
                              }
                            >
                              {yon.label}
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div key={index} className={"modalSelectDiv opacity05"}>
                    <div className="modalSelectDivTop">
                      <div className="modalSelectDiv__left">
                        <SonucSec />
                        {formation.label}
                      </div>
                      <div className="modalSelectDiv__right">
                        <div className="modalSelectDiv__right__period">
                          Periyot
                          <ArrowThinDown color="#9a9a9a" />
                        </div>
                        <div className="modalSelectDiv__right__yon">
                          {thisYon == "AYI" ? (
                            <ArrowDown color="#E12222" />
                          ) : thisYon == "BOĞA" ? (
                            <ArrowUp color="#22DE2A" />
                          ) : (
                            <ArrowSort color="#9a9a9a" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="modalSubDiv__footer__type">
              {ayrimi == 1 ? (
                <SonucSecildi onClick={() => handleAyri(0, "Ayırılmış")} />
              ) : (
                <SonucSec onClick={() => handleAyri(1, "Ayırıldı")} />
              )}
              Ayrı Olarak Listele
            </div>
            <div className="modalSubDiv__hr"></div>
            <div className="modalSubDiv">
              <h2 className="modalSubDiv__title">Hacim</h2>
              <p className="modalSubDiv__text">
                Aşağıdan dilediğiniz hacimleri seçerek filtreleme işlemine devam
                ediniz.
              </p>
            </div>
            <div className="modalSelectDiv all">
              <div className="modalSelectDivTop">
                <div className="modalSelectDiv__left mr-auto">
                  {hacimLists.length == updatedDataHacim.length ? (
                    <SonucSecildi onClick={(e) => handleHacim("None", e)} />
                  ) : (
                    <SonucSec onClick={(e) => handleHacim("All", e)} />
                  )}
                  Tümünü Seç
                </div>
                <div className="modalSelectDiv__right"></div>
              </div>
            </div>
            <div className="modalSelectDivs">
              {updatedDataHacim.map((formation, index) => {
                let secilen = hacimLists.some(
                  (item) => item === formation.slug
                );
                let thisFiltre = filtreHacimLists.findIndex(
                  (item) => item.value === formation.slug
                );
                let thisYon =
                  thisFiltre == -1
                    ? selectedYon
                    : filtreHacimLists[thisFiltre].yon;
                return (
                  <div
                    key={formation.slug}
                    className={
                      periyotOpen == formation.slug
                        ? "modalSelectDiv period"
                        : "modalSelectDiv"
                    }
                  >
                    <div className="modalSelectDivTop">
                      <div className="modalSelectDiv__left">
                        {secilen ? (
                          <SonucSecildi
                            onClick={(e) => handleHacim(formation.slug, e)}
                          />
                        ) : (
                          <SonucSec
                            onClick={(e) => handleHacim(formation.slug, e)}
                          />
                        )}
                        {formation.label}
                      </div>
                      <div className="modalSelectDiv__right">
                        <div
                          className="modalSelectDiv__right__yon"
                          onClick={() => handleYonHacimOpen(formation.slug)}
                        >
                          {thisYon == "AYI" ? (
                            <ArrowDown color="#E12222" />
                          ) : thisYon == "BOĞA" ? (
                            <ArrowUp color="#22DE2A" />
                          ) : (
                            <ArrowSort color="#9a9a9a" />
                          )}
                        </div>
                      </div>
                    </div>
                    {yonHacimOpen == formation.slug && (
                      <div className="modalSelectDiv__Bottom">
                        {dataYon2.map((yon) => (
                          <div
                            key={yon.label}
                            className="modalSelectDiv__periyot__item"
                            onClick={() =>
                              handleHacimYon(formation.slug, yon.label)
                            }
                          >
                            {yon.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="modalSubDiv__hr"></div>
            <div className="modalSubDiv">
              <h2 className="modalSubDiv__title">Ortalamalar</h2>
              <p className="modalSubDiv__text">
                Aşağıdan dilediğiniz ortalamaları seçerek filtreleme işlemine
                devam ediniz.
              </p>
            </div>
            <div className="modalSelectDiv all">
              <div className="modalSelectDivTop">
                <div className="modalSelectDiv__left mr-auto">
                  {ortalamaLists.length == updatedDataOrtalamalar.length ? (
                    <SonucSecildi onClick={(e) => handleOrtalama("None", e)} />
                  ) : (
                    <SonucSec onClick={(e) => handleOrtalama("All", e)} />
                  )}
                  Tümünü Seç
                </div>
                <div className="modalSelectDiv__right"></div>
              </div>
            </div>
            <div className="modalSelectDivs">
              {updatedDataOrtalamalar.map((formation, index) => {
                let secilen = ortalamaLists.some(
                  (item) => item === formation.slug
                );
                let thisFiltre = filtreOrtalamaLists.findIndex(
                  (item) => item.value === formation.slug
                );
                let thisYon =
                  thisFiltre == -1
                    ? selectedYon
                    : filtreOrtalamaLists[thisFiltre].yon;
                return (
                  <div
                    key={formation.slug}
                    className={
                      periyotOpen == formation.slug
                        ? "modalSelectDiv period"
                        : "modalSelectDiv"
                    }
                  >
                    <div className="modalSelectDivTop">
                      <div className="modalSelectDiv__left">
                        {secilen ? (
                          <SonucSecildi
                            onClick={(e) => handleOrtalama(formation.slug, e)}
                          />
                        ) : (
                          <SonucSec
                            onClick={(e) => handleOrtalama(formation.slug, e)}
                          />
                        )}
                        {formation.label}
                      </div>
                      <div className="modalSelectDiv__right">
                        <div
                          className="modalSelectDiv__right__yon"
                          onClick={() => handleYonOrtalamaOpen(formation.slug)}
                        >
                          {thisYon == "AYI" ? (
                            <ArrowDown color="#E12222" />
                          ) : thisYon == "BOĞA" ? (
                            <ArrowUp color="#22DE2A" />
                          ) : (
                            <ArrowSort color="#9a9a9a" />
                          )}
                        </div>
                      </div>
                    </div>
                    {yonOrtalamaOpen == formation.slug && (
                      <div className="modalSelectDiv__Bottom">
                        {dataYon2.map((yon) => (
                          <div
                            key={yon.label}
                            className="modalSelectDiv__periyot__item"
                            onClick={() =>
                              handleOrtalamaYon(formation.slug, yon.label)
                            }
                          >
                            {yon.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="modalSubDiv__hr"></div>
            <div className="modalSubDiv">
              <h2 className="modalSubDiv__title">İndikatörler</h2>
              <p className="modalSubDiv__text">
                Aşağıdan dilediğiniz indikatörleri seçerek filtreleme işlemine
                devam ediniz.
              </p>
            </div>
            <div className="modalSelectDiv all">
              <div className="modalSelectDivTop">
                <div className="modalSelectDiv__left mr-auto">
                  {indicatorLists.length == updatedDataIndicators.length ? (
                    <SonucSecildi onClick={(e) => handleIndicator("None", e)} />
                  ) : (
                    <SonucSec onClick={(e) => handleIndicator("All", e)} />
                  )}
                  Tümünü Seç
                </div>
                <div className="modalSelectDiv__right"></div>
              </div>
            </div>
            <div className="modalSelectDivs">
              {updatedDataIndicators.map((formation, index) => {
                let secilen = indicatorLists.some(
                  (item) => item === formation.slug
                );
                let thisFiltre = filtreIndicatorLists.findIndex(
                  (item) => item.value === formation.slug
                );
                let thisYon =
                  thisFiltre == -1
                    ? selectedYon
                    : filtreIndicatorLists[thisFiltre].yon;
                return (
                  <div
                    key={formation.slug}
                    className={
                      periyotOpen == formation.slug
                        ? "modalSelectDiv period"
                        : "modalSelectDiv"
                    }
                  >
                    <div className="modalSelectDivTop">
                      <div className="modalSelectDiv__left">
                        {secilen ? (
                          <SonucSecildi
                            onClick={(e) => handleIndicator(formation.slug, e)}
                          />
                        ) : (
                          <SonucSec
                            onClick={(e) => handleIndicator(formation.slug, e)}
                          />
                        )}
                        {formation.label}
                      </div>
                      <div className="modalSelectDiv__right">
                        <div
                          className="modalSelectDiv__right__yon"
                          onClick={() => handleYonIndicatorOpen(formation.slug)}
                        >
                          {thisYon == "AYI" ? (
                            <ArrowDown color="#E12222" />
                          ) : thisYon == "BOĞA" ? (
                            <ArrowUp color="#22DE2A" />
                          ) : (
                            <ArrowSort color="#9a9a9a" />
                          )}
                        </div>
                      </div>
                    </div>
                    {yonIndicatorOpen == formation.slug && (
                      <div className="modalSelectDiv__Bottom">
                        {dataYon2.map((yon) => (
                          <div
                            key={yon.label}
                            className="modalSelectDiv__periyot__item"
                            onClick={() =>
                              handleIndicatorYon(formation.slug, yon.label)
                            }
                          >
                            {yon.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="modalSubDiv__footer">
              <Button
                onClick={() => funcSelectReset()}
                variant="danger"
                className="modalSonucButton"
              >
                {isProcessing ? (
                  <PulseLoader color="#fff" aria-label="Resetleniyor..." />
                ) : (
                  "Filtreyi Resetle"
                )}
              </Button>
              <div className="modalSubDiv__footer__button">
                <Button
                  onClick={() => _getFormations()}
                  className="modalSonucButton"
                >
                  {isProcessing ? (
                    <PulseLoader
                      color="#fff"
                      aria-label="Sonuçlar Getiriliyor..."
                    />
                  ) : (
                    "Sonuçları Listele"
                  )}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {visibleAlarm && (
        <Modal
          show={visibleAlarm}
          onHide={handleAlarmClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Alarm Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body">
                <Form.Control
                  type="text"
                  name="alarm"
                  placeholder="Alarm adı giriniz"
                  value={alarm}
                  onChange={(e) => setAlarm(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
              </div>
            </div>
            <Button
              onClick={() => handleAlarmEkle()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Alarmı Kaydet"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleFormul && (
        <Modal
          show={visibleFormul}
          onHide={handleFormulClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Formül Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body modalAlarmDiv__body__formul">
                <Form.Control
                  type="text"
                  name="formul"
                  placeholder="Formül adı giriniz"
                  value={formul}
                  onChange={(e) => setFormul(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
              </div>
              <div className="modalAlarmDiv__body modalAlarmDiv__body__formul">
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  name="formulNote"
                  placeholder="Formül notu giriniz"
                  value={formulNote}
                  onChange={(e) => setFormulNote(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
              </div>
            </div>
            <Button
              onClick={() => handleFormulEkle()}
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Formülü Kaydet"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleAlarmFiyat && (
        <Modal
          show={visibleAlarmFiyat}
          onHide={handleAlarmFiyatClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {alarmName} Fiyat Alarm Takibi Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body">
                <Form.Control
                  type="text"
                  name="alarm"
                  placeholder="Fiyat Yazınız"
                  value={alarmFiyat}
                  onChange={(e) => setAlarmFiyat(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
                <Form.Select aria-label="Default select example">
                  <option>Tipini Seçiniz</option>
                  <option value="1">{"> Üstünde"}</option>
                  <option value="2">{"< Altında"}</option>
                  <option value="3">{"= Eşittir"}</option>
                </Form.Select>
              </div>
            </div>
            <Button
              onClick={() => handleAlarmFiyatEkle()}
              className="modalSonucButton"
              style={{ width: 215 }}
            >
              {isProcessing ? (
                <PulseLoader
                  color="#fff"
                  aria-label="Sonuçlar Getiriliyor..."
                />
              ) : (
                "Fiyat Alarmını Kaydet"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {visibleListelerim && (
        <Modal
          show={visibleListelerim}
          onHide={handleListelerimClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {listeName} Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalListeTabDiv">
              <div className="modalListeTabDiv__body">
                <div
                  className={
                    selectListeTab == 1
                      ? "modalListeTabDiv__body__tabs active"
                      : "modalListeTabDiv__body__tabs"
                  }
                  onClick={() => setSelectListeTab(1)}
                >
                  Listelerime Ekle
                </div>
                <div
                  className={
                    selectListeTab == 2
                      ? "modalListeTabDiv__body__tabs active"
                      : "modalListeTabDiv__body__tabs"
                  }
                  onClick={() => setSelectListeTab(2)}
                >
                  Favorilerime Ekle
                </div>
              </div>
            </div>
            <div className="modalListeTabDiv__list">
              {selectListeTab == 1 ? (
                <>
                  <div className="modalListeScroll">
                    {listeler &&
                      listeler.map((item) => (
                        <div
                          key={item.id}
                          className="modalListeTabDiv__list__item"
                          onClick={() => _listeSelect(item)}
                        >
                          {selectListe.id == item.id ? (
                            <SonucSecildi />
                          ) : (
                            <SonucSec />
                          )}
                          <div>{item.name}</div>
                        </div>
                      ))}
                  </div>
                  <div
                    className="modalListeTabDiv__list__item"
                    onClick={() => _listeSelect("new")}
                  >
                    {selectListe == "new" ? <SonucSecildi /> : <SonucSec />}
                    <Form.Control
                      type="text"
                      name="liste"
                      placeholder="Yeni Liste Oluştur"
                      value={liste}
                      onChange={(e) => setListe(e.target.value)}
                      className="modalAlarmDiv__body__input"
                    />
                  </div>
                  <Button
                    onClick={() => handleListemeEkle()}
                    className="modalSonucButton"
                    style={{ width: 215 }}
                  >
                    {isProcessing ? (
                      <PulseLoader
                        color="#fff"
                        aria-label="Sonuçlar Getiriliyor..."
                      />
                    ) : (
                      "Listeye Ekle"
                    )}
                  </Button>
                </>
              ) : (
                <></>
              )}
              {selectListeTab == 2 && (
                <>
                  <Button
                    onClick={() => handleFavoriEkle()}
                    className="modalSonucButton"
                    style={{ width: 215 }}
                  >
                    {isProcessing ? (
                      <PulseLoader
                        color="#fff"
                        aria-label="Sonuçlar Getiriliyor..."
                      />
                    ) : (
                      "Favoriye Ekle"
                    )}
                  </Button>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {modalGrafik && (
        <Modal
          show={modalGrafik}
          onHide={handleGrafikClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ChartComponent
              urlApi={urlApi}
              id={formationId}
              load={loadChart}
              access_token={user.access_token}
              theme={theme}
            />
          </Modal.Body>
        </Modal>
      )}
      {modalIndicator && (
        <Modal
          show={modalIndicator}
          onHide={handleIndicatorClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <PuanComponent
              urlApi={urlApi}
              name={puanName}
              access_token={user.access_token}
            />
          </Modal.Body>
        </Modal>
      )}
      {modalHeikin && (
        <Modal
          show={modalHeikin}
          onHide={handleHeikinClose}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Button
              variant="default"
              onClick={() => handleHeikinFiltre("heikin", "green1", false)}
              className={`btn-sm rounded-pill m-1 btn-success success1`}
            >
              Heikin (G) İlk Pozitif
            </Button>
            <Button
              variant="default"
              onClick={() => handleHeikinFiltre("heikin", "green1", true)}
              className={`btn-sm rounded-pill m-1 btn-success success1`}
            >
              {"Heikin (G) İlk Pozitif & CMF < -0.1"}
            </Button>
            <Button
              variant="default"
              onClick={() => handleHeikinFiltre("heikin", "green2", false)}
              className={`btn-sm rounded-pill m-1 btn-success success2`}
            >
              Heikin (G) Kararlı Pozitif
            </Button>
            <Button
              variant="default"
              onClick={() => handleHeikinFiltre("heikin_1w", "green1", false)}
              className={`btn-sm rounded-pill m-1 btn-success success1`}
            >
              Heikin (H) İlk Pozitif
            </Button>
            <Button
              variant="default"
              onClick={() => handleHeikinFiltre("heikin_1w", "green1", true)}
              className={`btn-sm rounded-pill m-1 btn-success success1`}
            >
              {"Heikin (H) İlk Pozitif & CMF < -0.1"}
            </Button>
            <Button
              variant="default"
              onClick={() => handleHeikinFiltre("heikin_1w", "green2", false)}
              className={`btn-sm rounded-pill m-1 btn-success success2`}
            >
              Heikin (H) Kararlı Pozitif
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default AnaKumanda;
